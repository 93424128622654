<template>
  <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
    <div class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      href="/products/urocrete">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-80 h-80">
          <img
            class="mx-auto group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
            src="../assets/urobondmaxi.png" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 sm:px-0">
          <h3
            class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            UROBOND 600 MAXI
          </h3>
          <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
            <span class="font-semibold text-gray-700">Tile Adhesive (Pre-Mix)</span>
            <br />
            PREMIUM GRADE Cementitious Tile Adhesive
          </p>
          <h3
            class="text-xl underline mt-5 font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            Description
          </h3>
          <p class="mt-5">UROBOND 600 MAXI is a high performance premixed based cementitious tile adhesive, specially design for large-size porcelain, homogenous tiles and natural stone laying on heavy duty area.</p>
          <p class="mt-5">UROBOND 600 MAXI is formulated with top quality polymer modified adhesive and improved (2) slip resistant (T) cementitious adhesive (C) with extended open time (E) of class C2TE.</p>
        </div>
      </div>
    </div>

  </div>
  <div class="bg-green-900 text-white">
    <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="grid grid-cols-1 gap-4">
        <div>
          <h5 class="mb-5 font-bold underline">ADVANTAGES</h5>
          <strong>Polymer modified formulation result in high bonding strength performance.</strong>
          <div>
            - Suitable area for weather shield and heavy duty, e.g. Car Porch area, swimming pool.<br />
            - Deformability makes it a flexible and versatile product.<br />
            - Pre-packed for convenience in handling, mixing and application.<br />
            - Mix with water only and easy to apply.<br />
            - Eco-friendly, Organic, non-toxic products.<br />
            <br />

          </div>
        </div>


      </div>
    </div>
  </div>
  <div>
    <div class="mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:items-center lg:px-8">
      <div class="font-bold mb-5 underline">USES</div>
      <strong>For Tiles Installation</strong><br/>
      - At Car Porch or Balcony area (mix with <strong>UROCRETE™</strong>), Swimming pool (mix with
          <strong>UROLASTIC 125</strong>).<br />
          - Can be used for floor and wall tiles installation, interior and exterior application.<br />
          - Ideal for most types of tiles: porcelain, ceramics, terracotta, homogeneous, mosaics, marble, natural stone
          etc.<br />
          - Used to fix tiles onto concrete, brickwork and rendered surfaces.<br />
      <div class="font-bold mb-5 mt-5 underline">SURFACE PREPARATION</div>
      <div>
        <strong>For Tiles Installation</strong><br />
          - Ensure the back of the tiles is free of dirt, wax, grease, oil, water and other contaminants.<br />
          - Ensure the surface of substrate receiving <strong>UROBOND 600 MAXI</strong> adhesive mortar is sound, clean,
          dry and free from movement, dust, oil, grease, wax paint, dirt, laitance, efflorescence and other loose
          contaminants.<br />
          - Cementitious substrate must not be subject to shrinkage after tile installation. Therefore, in mild weather,
          rendered surfaces must be allowed to cure for at least 28 days.<br />
          - Gypsum substrate, anhydrite and porous surfaces must be perfectly dry, sufficient hard and free from dust.
          They must be treated with <strong>UROPRIME</strong>.<br />
      </div>
      <div class="font-bold mb-5 mt-5 underline">MIXING</div>
      <div>
        <p>- Mix 25kg <strong>UROBOND 600 MAXI</strong> with 6-7 litres of clean water, mixing continuously until a
          smooth, lump-free, creamy consistency is achieved.<br />
          - Allow the mix to stand for 5 minutes and remix prior to use. Mix lasts approximately 1-2 hours, depend on
          the temperature.<br />
        </p>
      </div>
      <div class="font-bold mb-5 mt-5 underline">APPLICATION</div>
      <div>
        <p>
          - Pre-wet the substrate before application, do not allow water ponding prior to application of notched trowel
          of <strong>UROBOND 600 MAXI</strong> admixture.<br />
          - Spread the adhesive onto the substrate. The choice of the trowel should be governed by the size of the
          tiles, depth of knobs, grooves or ridges on the back of the tiles and the evenness of the substrate.<br />
          - Apply ‘Scratching/Buttering Coat’ of <strong>UROBOND 600 MAXI</strong> adhesive mortar at the back of the
          tiles/receiving
          surface of the tiles with sponge trowel, immediately notch trowel on floor and lay following tiles for better
          adhesion and support occurs especially for non-porous tiles. Do not attempt to leave ‘Scratching/Buttering
          Coat’ overnight.<br />
          - Ensure there are no voids under the tiles.<br />
          - Do not allow the adhesive to skin over prior to tilling. If not, remove the adhesive or re-trowel the
          adhesive.<br />
          - Allow 24 hours curing before grouting for floor and allow 4-8 hours curing before grouting for wall.<br />
          - Floor are set to light foot traffic after approximately 24 hours.<br />

        </p>
      </div>
      <div class="font-bold mb-5 mt-5 underline">LIMITATIONS</div>
      <div>
        <p>
          Tiling installed with <strong>UROBOND 600 MAXI</strong> must not be washed or exposed to rain for at least 24
          hours and must be protected from frost and strong sun for at least 5-7 days.
        </p>
      </div>
      <img
        class="mx-auto mt-5 mb-10 group-hover:scale-105 transition-transform duration-500 ease-in-out h-auto w-full top-0 start-0 rounded-xl"
        src="../assets/step7.png" alt="Image Description">

      <div class="font-bold mb-5 mt-5 underline">CLEANING</div>
      <div>
        Wash and clean the tools and bucket with water when the mortar is still wet.
      </div>
      <div class="font-bold mb-5 mt-5 underline">HEALTH & SAFETY</div>
      <div>
        This product is non-toxic. In cases of allergic and sensitive skin contact, flush and clean with water. If severe medical reaction happened please seek for professional help.
      </div>
      <div class="font-bold mb-5 mt-8 underline">TECHNICAL DATA</div>
      <div class="flex flex-col mt-5">
        <div class="-m-1.5 overflow-x-auto">
          <div class="p-1.5 min-w-full inline-block align-middle">
            <div class="overflow-hidden">
              <div class="font-bold">PRODUCT DATA</div>
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">PACKAGING
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">25kg / bag</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COLOUR
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Grey or White Powder</td>
                  </tr>
                </tbody>
              </table>
              <div class="font-bold mt-5">APPLICATION DATA</div>
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">MIXING RATIO	
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">25kg : 6 – 7 Litres of clean water</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">DENSITY OF THE MIX	
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">±1,500 kg/m3</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">CONSISTENCY OF MIX	
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Pasty</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">PH OF MIX
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">>12</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">APPLICATION TEMPERATURE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">+5°C to +35°C</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">CONSUMPTION
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">	1.2kg/m²/mm</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">OPEN TIME
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">> 30 minutes (Subject to humidity and temperature)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">POT LIFE	
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">	2 Hours at 20°C</td>
                  </tr>
                </tbody>
              </table>
              <div class="font-bold mt-5">STORAGE DATA</div>
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">SHELF LIFE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">6 months from production date in original packaging</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Normal, dry conditions at ambient temperature. Store out of direct sunlight.</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE TEMPERATURE	
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">10°C - 37°C</td>
                  </tr>
                </tbody>
              </table>
              <div class="font-bold mt-5">PERFORMANCES DATA</div>
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">TENSILE ADHESION STRENGTH (AFTER 28 DAYS)<br/>-INITIAL STRENGTH:<br/>-HEAT AGING:<br/>-WATER IMMERSION:
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">≥1.0 N/mm²<br/>≥1.0 N/mm²<br/>≥1.0 N/mm²</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">RESISTANCE TO ALKALIS	
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Excellent</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">RESISTANCE TO OILS		
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Excellent (poor to vegetable oils)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">RESISTANCE TO SOLVENTS		
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Excellent</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-gray-400 italic mt-5">
          The information provided on this data sheet is not intended to be complete and is provided as general advice
          only. It is the user’s responsibility to ensure that the product is suitable for its intended purpose. As we
          have no control over the treatment of the product, the standard of surface preparation, or other factors, we are
          not responsible for any damages or injury, including but not limited to special or consequential damages which
          may result from the use of our products, which includes any damages or injury caused by any failure of
          performance. The information contained in this data sheet may be modified by us from time to time, without any
          notice arising from our continuous product development activities.
        </div>
      </div>
    </div>
  </div>
  <ProductListComponent />
</template>
    
<script>
import ProductListComponent from "./ProductListComponent.vue";
export default {
  name: 'Urobond600MaxiPage',
  components: {
    ProductListComponent
  }
}
</script>
    