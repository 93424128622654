<template>
  <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
    <div class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      href="/products/urocrete">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-80 h-80">
          <img
            class="mx-auto group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
            src="../assets/urohancer.png" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 sm:px-0">
          <h3
            class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            UROHANCER
          </h3>
          <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
            <span class="font-semibold text-gray-700">Cement Enhancer/Cement Additive</span>
            <br />
            For Tiles Installation with Ordinary Portland Cement
          </p>
          <h3
            class="text-xl underline mt-5 font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            Description
          </h3>
          <p class="mt-5">UROHANCER is formulated as a water dissoluble cement enhancer to add into OPC cement mortar.
            It is designed to improve OPC’s tensile adhesion, increases its flexural strength in bending, and to enhance
            it abrasion resistance characteristic. OPC cement mortar added with UROHANCER makes a good rheology, and
            increases its workability for smooth and easy tiles installation.</p>

        </div>
      </div>
    </div>

  </div>
  <div class="bg-green-500 text-white">
    <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <h5 class="mb-5 font-bold underline">ADVANTAGES</h5>
          <div>
            <div class="font-semibold">For Tiles Installation</div>
            - Improves performance of OPC cement mortar<br />
            - Enhanced workability of OPC cement mortar<br />
            - Enhanced water retention ability of OPC cement mortar<br />
            - Extended OPC cement mortar open time / prolonged life span<br />
            - Excellent Initial Strength for bigger and heavy tiles or marble<br />
            - High tensile pulling strength combination with UROCRETE™<br />
            - High Anti-sagging performances<br />
            - Enormous cost saving, in replacement of conventional 25kg pre-mix tile adhesive<br />
          </div>
        </div>

        <div>
          <h5 class="mb-5 font-bold underline">SURFACE PREPARATION</h5>
          <p> - All surfaces must be structurally sound, clean, dry and free from movement, dust, oil, grease, wax and
            other contaminants.<br />
            - Cementitious substrate must not be subject to shrinkage after tile installation. Therefore, in mild
            weather, rendered surfaces must be allowed to cure for at least 28 days.<br />
            - Pre-wet the substrate before application, do not allow water ponding prior to application of notched
            trowel of UROHANCER admixture.<br /></p>
          <div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:items-center lg:px-8">
      <div class="font-bold mb-5 underline">USES</div>
      <div class="font-semibold">For Tiles Installation</div>
      <div>
        - <strong>UROHANCER</strong> is an additive to add with OPC cement mortar as a tile bedding mortar.<br />
        - <strong>UROHANCER</strong> is use conjunctionally with <strong>UROCRETE™</strong> for non-porous tiles
        installation eg: Porcelain, Homogenous, Marble, Granite, Stone. With <strong>UROCRETE™</strong> as a bonding
        agent apply on the surface of receiving tile bedding mortar.<br />
        - <strong>UROHANCER</strong> is use conjunctionally with <strong>UROPRIME</strong> for porous tiles
        installation eg: Ceramics, Terracotta etc.<br />
        - <strong>UROHANCER</strong> cement mortar is allowed to add with washed fine sand to further cost saving and
        for workability purpose especially for floor tiles.<br />
        - In replacement of conventional pre-mix Tile Adhesive, <strong>UROHANCER</strong> cement mortar is enormous
        time and cost saving with improved bonding ability.<br />
      </div>
      <div class="font-bold mb-5 mt-5 underline">MIXING</div>
      <div>Prepare a 20L kind of bucket/pail/container, add in 7-8 litre of water, add in UROHANCER 80g, stir it with
        electrical mixer, add in 22kg of Ordinary Portland Cement (OPC) and stir it again with electrical mixer until a
        lump-free, creamy consistency admixture is achieved. Allow the mix to stand for 5 minutes and remix prior to
        use. Mix lasts approximately > 30 minutes, depend on the temperature and humidity.
        *Mixing of 5-10kg of washed fine sand is allowed.</div>
      <div class="font-bold mb-5 mt-5 underline">APPLICATION</div>
      <div>- For Non-porous Tiles (Porcelain, Homogeneous, Marble etc)<br />
        - Apply <strong>UROCRETE™</strong> with brush or roller on the receiving surface of <strong>UROHANCER</strong>
        cement mortar, ensuring an even distribution. Minimises overlapping of material on same applying area. Leave
        to dry for approximately 30 minutes before next application. Proceed with mixing of UROHANCER cement
        mortar.<br />
        - For Porous Tiles (Ceramics, Teracotta etc)<br />
        - Preparation of ceramics tiles such as apply with <strong>UROPRIME</strong>, then proceed with the mixing of
        <strong>UROHANCER</strong> cement mortar.<br />
        - Spread the <strong>UROHANCER</strong> cement mortar onto the substrate. The choice of the trowel should be
        governed by the size of the tiles, depth of knobs, grooves or ridges on the back of the tiles and the evenness
        of the substrate.<br />
      </div>
      <img
        class="mx-auto mt-5 mb-10 group-hover:scale-105 transition-transform duration-500 ease-in-out h-auto w-full top-0 start-0 rounded-xl"
        src="../assets/mixingstep1-3.png" alt="Image Description">

      <div class="font-bold mb-5 mt-5 underline">CLEANING</div>
      <div>
        Wash and clean the tools and bucket with water when the mortar is still wet.
      </div>
      <div class="font-bold mb-5 mt-5 underline">HEALTH & SAFETY</div>
      <div>
        This product is non-toxic. In cases of allergic and sensitive skin contact, flush and clean with water. If
        severe medical reaction happened please seek for professional help
      </div>
      <div class="font-bold mb-5 mt-8 underline">TECHNICAL DATA</div>
      <div class="flex flex-col mt-5">
        <div class="-m-1.5 overflow-x-auto">
          <div class="p-1.5 min-w-full inline-block align-middle">
            <div class="overflow-hidden">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                      PACKAGING</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">100 Bag / Carton
                      (80 gram / bag)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COLOUR
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">White powder</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">MIXING
                      RATIO</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Urohancer (80gram)
                      : 22kg Cement</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                      CONSUMPTION</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">1.2kg/m²/mm</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">OPEN
                      TIME</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">> 30 minutes
                      (Subject to humidity and temperature)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">SHELF
                      LIFE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">18 months</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Normal, dry
                      conditions at ambient temperature. Store out of direct sunlight.</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                      TEMPERATURE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">10°C - 37°C</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-gray-400 italic mt-5">
          The information provided on this data sheet is not intended to be complete and is provided as general advice
          only. It is the user’s responsibility to ensure that the product is suitable for its intended purpose. As we
          have no control over the treatment of the product, the standard of surface preparation, or other factors, we
          are not responsible for any damages or injury, including but not limited to special or consequential damages
          which may result from the use of our products, which includes any damages or injury caused by any failure of
          performance. The information contained in this data sheet may be modified by us from time to time, without any
          notice arising from our continuous product development activities.
        </div>
      </div>
    </div>
  </div>
  <ProductListComponent />
</template>

<script>
import ProductListComponent from "./ProductListComponent.vue";
export default {
  name: 'UrohancerPage',
  components: {
    ProductListComponent
  }
}
</script>