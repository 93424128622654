<template>
  <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
    <div class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      href="/products/urocrete">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-80 h-80">
          <img
            class="mx-auto group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
            src="../assets/uroplaster.png" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 sm:px-0">
          <h3
            class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            URO PLASTER BOND
          </h3>
          <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
            <span class="font-semibold text-gray-700">Plastering Bonding Agent</span>
            <br />
            For Light Weight Blocks, RC & Shear Wall Plastering
          </p>
          <h3
            class="text-xl underline mt-5 font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            Description
          </h3>
          <p class="mt-5">URO PLASTER BOND is formulated as a bonding agent for internal and external plastering work.
            With the characteristic of polymerized film forming, URO PLASTER BOND designed to receive subsequent
            cementitious mortar such as plastering mortar, cement render, or skim coating.</p>
        </div>
      </div>
    </div>

  </div>
  <div class="bg-blue-800 text-white">
    <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="grid grid-cols-1 gap-4">
        <div>
          <h5 class="mb-5 font-bold underline">ADVANTAGES</h5>
          <div>
            - Eliminates the application of ‘Spatter Dash’ or commonly call as stamping/chopping for RC & Shear wall
            Plastering<br />
            - High bonding performance to unconventional plastering surfaces<br />
            - Suitable to bond various cementitious products such as plastering mortar, cement render and skim
            coat.<br />
            - Extended open time up to 7 days for internal and 3 days for external.<br />
            - Enormous time saving & cost saving over conventional method<br />
            - Simplicity - Apply with roller on the smooth surface prior to plastering, rendering or skim coating.<br />
            <br />

          </div>
        </div>


      </div>
    </div>
  </div>
  <div>
    <div class="mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:items-center lg:px-8">
      <div class="font-bold mb-5 underline">USES</div>
      - <strong>URO PLASTER BOND</strong> for Light Weight Block, RC/Shear Wall Plastering is strongly suggested to
      use with UROPLAST
      PREMIUM + OPC + Fine Sand Plastering mortar to achieve its optimum bonding strength.<br>
      - Apply with Roller, thin continuous unbroken film is recommended. Effective time for plastering work of URO
      PLASTER BOND is within 7 days for internal and 3 days for external.<br>
      - Can be apply on RC & Shear wall, light weight/ALC/AAC block, plywood, cement board, steel & etc.
      <div class="font-bold mb-5 mt-5 underline">SURFACE PREPARATION</div>
      <div>
        <p>All surfaces must be structurally sound, clean, dry and free from movement, dust, oil, grease, wax, paint, dirt, laitance, efflorescence and other loose contaminants.</p>
      </div>
      <div class="font-bold mb-5 mt-5 underline">MIXING</div>
      <div>
        <p>Stir well before use, do not dilute with water, do not add any admixture.</p>
      </div>
      <div class="font-bold mb-5 mt-5 underline">APPLICATION</div>
      <div>
        <p>-Apply with brush or roller, ensuring an even distribution. Minimises overlapping of material on same
          applying area. Leave to dry for approximately 30 minutes before next application.
          <br />- A pre-grout layer (UROPLAST PREMIUM + OPC + FINE SAND) is advisable to plaster (10-15mm) on and leave
          for approximately 1 hours, set at about 60-70% and continue with 2nd plastering layer or finishing layer
          (10-15mm).
          <br />- To achieve optimum gripping between plastering layers, it is important to monitor the setting time of
          pre-grout level and 2nd layer for consequence top up layer to prevent low gripping ability.
        </p>
      </div>
      <div class="font-bold mb-5 mt-5 underline">LIMITATIONS</div>
      <div>
        <p>
          - Limited to the effective time of UROPLASTER BOND is within 7 days for internal and 3 days for
          external.<br />
          - Do not commence any construction work if the applied coating has been passed the effective time.<br />
          - Do not apply to ponded areas such as swimming pool, water feature wall or retaining walls.<br />
        </p>
      </div>
      <img
        class="mx-auto mt-5 mb-10 group-hover:scale-105 transition-transform duration-500 ease-in-out h-auto w-full top-0 start-0 rounded-xl"
        src="../assets/step4.png" alt="Image Description">

      <div class="font-bold mb-5 mt-5 underline">CLEANING</div>
      <div>
        Wash and clean with water when URO PLASTER BOND is still wet.
      </div>
      <div class="font-bold mb-5 mt-5 underline">HEALTH & SAFETY</div>
      <div>
        This product is non-toxic. In cases of allergic and sensitive skin contact, flush and clean with water. If severe medical reaction happened please seek for professional help.
      </div>
      <div class="font-bold mb-5 mt-8 underline">TECHNICAL DATA</div>
      <div class="flex flex-col mt-5">
        <div class="-m-1.5 overflow-x-auto">
          <div class="p-1.5 min-w-full inline-block align-middle">
            <div class="overflow-hidden">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">PACKAGING
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">	19 Litre / Pail</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COLOUR
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Blue</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">TOOL</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Roller, brush or spray</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COVERAGE AREA</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Approximately 10m2 – 15m2 / Litre (Subject to method of application, type, temperature, texture and porosity of substrate)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">CURING
                      TIME</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Approximately 30 minutes (Subject to humidity and temperature)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">EFFECTIVE
                      TIME</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">7 Days (Internal) ; 3 Days (External)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">SHELF
                      LIFE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">12 months</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Normal, dry
                      conditions at ambient temperature. Store out of direct sunlight.</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                      TEMPERATURE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">10°C - 37°C</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">DENSITY
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">±1.05kg/litre</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-gray-400 italic mt-5">
          The information provided on this data sheet is not intended to be complete and is provided as general advice
          only. It is the user’s responsibility to ensure that the product is suitable for its intended purpose. As we
          have no control over the treatment of the product, the standard of surface preparation, or other factors, we are
          not responsible for any damages or injury, including but not limited to special or consequential damages which
          may result from the use of our products, which includes any damages or injury caused by any failure of
          performance. The information contained in this data sheet may be modified by us from time to time, without any
          notice arising from our continuous product development activities.
        </div>
      </div>
    </div>
  </div>
  <ProductListComponent />
</template>
    
<script>
import ProductListComponent from "./ProductListComponent.vue";

export default {
  name: 'UroPlasterBondPage',
  components: {
    ProductListComponent
  }
}
</script>
    