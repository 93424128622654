<template>
  <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
    <div class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      href="/products/urocrete">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-80 h-80">
          <img
            class="mx-auto group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
            src="../assets/uroplast premium.png" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 sm:px-0">
          <h3
            class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            UROPLAST PREMIUM
          </h3>
          <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
            <span class="font-semibold text-gray-700">Plastering & Mortar Additives</span>
            <br />
            For clay brickwork and plastering
          </p>
          <h3
            class="text-xl underline mt-5 font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            Description
          </h3>
          <p class="mt-5">UROPLAST PREMIUM is high quality powder additives concentrate formulated for better dispersing of Ordinary Portland Cement into plastering and brick laying cement mortar. The UROPLAST PREMIUM systems produces better quality work, save time therefore optimizes your labour cost.</p>
        </div>
      </div>
    </div>

  </div>
  <div class="bg-orange-500 text-white">
    <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="grid grid-cols-1 gap-4">
        <div>
          <h5 class="mb-5 font-bold underline">ADVANTAGES</h5>
          <div>
            <strong>For Plastering</strong><br/>

            - Increase bonding strength<br />
            - Excellent workability<br />
            - No accidental cost<br />
            - Minimizes shrinkage crack<br />
            - Easy handling<br />

          </div>
        </div>


      </div>
    </div>
  </div>
  <div>
    <div class="mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:items-center lg:px-8">
      <div class="font-bold mb-5 underline">USES</div>
      UROPLAST PREMIUM is a plasticizer to improve adhesion and workability properties of plastering and brick laying. It enhances the dispersion, pliability, adhesiveness and durability of normal Portland cement.
      <div class="font-bold mb-5 mt-5 underline">SURFACE PREPARATION</div>
      <div>
        <p>All surfaces must be structurally sound, clean, dry and free from movement, dust, oil, grease, wax and other contaminants.</p>
      </div>
      <div class="font-bold mb-5 mt-5 underline">MIXING</div>
      <div>
        <p>1) Put in 25-30 Litre clean water.<br />
          2) Put in one bag of UROPLAST PREMIUM (100 gram).<br />
          3) Mix with 50-75kg cement.<br />
          4) Mix with ±250kg washed fine sand.<br />
          5) Mix for 5 minutes until a smooth, lump-free admixture is achieved. <br />
        </p>
      </div>
      
      <div class="font-bold mb-5 mt-5 underline">LIMITATIONS</div>
      <div>
        <p>
          Use only according to recommended dosage. Over dosage may cause retarding effect to cement mortar.
        </p>
      </div>
      <img
        class="mx-auto mt-5 mb-10 group-hover:scale-105 transition-transform duration-500 ease-in-out h-auto w-full top-0 start-0 rounded-xl"
        src="../assets/step5.png" alt="Image Description">

      <div class="font-bold mb-5 mt-5 underline">CLEANING</div>
      <div>
        Wash and clean the tools and bucket with water when the mortar is still wet.
      </div>
      <div class="font-bold mb-5 mt-5 underline">HEALTH & SAFETY</div>
      <div>
        This product is non-toxic. In cases of allergic and sensitive skin contact, flush and clean with water. If severe medical reaction happened please seek for professional help.</div>
      <div class="font-bold mb-5 mt-8 underline">TECHNICAL DATA</div>
      <div class="flex flex-col mt-5">
        <div class="-m-1.5 overflow-x-auto">
          <div class="p-1.5 min-w-full inline-block align-middle">
            <div class="overflow-hidden">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">PACKAGING
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">100 Bag / Carton (100 gram / bag)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COLOUR
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">White powder</td>
                  </tr>
                
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">SHELF
                      LIFE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">18 months</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Normal, dry conditions at ambient temperature. Store out of direct sunlight.</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                      TEMPERATURE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">10°C - 37°C</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-gray-400 italic mt-5">
          The information provided on this data sheet is not intended to be complete and is provided as general advice
          only. It is the user’s responsibility to ensure that the product is suitable for its intended purpose. As we
          have no control over the treatment of the product, the standard of surface preparation, or other factors, we are
          not responsible for any damages or injury, including but not limited to special or consequential damages which
          may result from the use of our products, which includes any damages or injury caused by any failure of
          performance. The information contained in this data sheet may be modified by us from time to time, without any
          notice arising from our continuous product development activities.
        </div>
      </div>
    </div>
  </div>
  <ProductListComponent />
</template>
    
<script>
import ProductListComponent from "./ProductListComponent.vue";
export default {
  name: 'UroplastPremiumPage',
  components: {
    ProductListComponent
  }
}
</script>
    