<template>
  <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
    <div class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      href="/products/urocrete">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-80 h-80">
          <img
            class="mx-auto group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
            src="../assets/uroprime.png" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 sm:px-0">
          <h3
            class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            UROPRIME
          </h3>
          <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
            <span class="font-semibold text-gray-700">Multi-purpose Polymer-based Primer</span>
            <br />
            Crystallisation Agent & Solutions for Concrete Shrinkage Crack
          </p>
          <h3
            class="text-xl underline mt-5 font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            Description
          </h3>
          <p class="mt-5">UROPRIME is formulated as a polymer-based primer best uses for surface preparation. Also, with
            high penetration ability, UROPRIME effectively serve as a solution for water leaking issues arise from
            concrete shrinkage crack.</p>
        </div>
      </div>
    </div>

  </div>
  <div class="bg-blue-500 text-white">
    <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="grid grid-cols-1 gap-4">
        <div>
          <h5 class="mb-5 font-bold underline">ADVANTAGES</h5>
          <div>
            <div class="font-semibold">For Surface Preparation</div>
            - Effective, easy solution for dusty and powdery surface<br />
            - Excellent and high penetration into cement-based surface<br />
            - Durability - UV and weather resistant, water repellent ability<br />
            - Flexibility - Good in film forming and binding properties<br />
            <br />
            <div class="font-semibold">For Solutions on concrete shrinkage crack (to terminate water leaking)</div>
            - Effective and permanent solution for water leaking on shrinkage crack<br />
            - Enormous time and cost saving when eliminates hustle extra work on hacking<br />
            - Clean and simplicity solutions for applicator and contractor<br />
          </div>
        </div>


      </div>
    </div>
  </div>
  <div>
    <div class="mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:items-center lg:px-8">
      <div class="font-bold mb-5 underline">USES</div>
      <div class="font-semibold">SURFACE PREPARATION</div>
      <div><strong>Prior to Tiles Installation</strong><br>
        - Treat powdery substance on ceramic tiles base surface (receiving adhesive surface)<br>
        - Treat painted, skim coated and chipped surface for tiles installation<br>
        - For Direct Wall Tiles Installation on RC/Shear Wall<br><br>
        <strong>Prior to RC/Shear Wall Rendering and skim coating</strong><br>
        - Treat dust on RC/Shear wall surface for rendering and skim coating<br><br>
        <strong>Prior to Waterproofing membrane</strong><br>
        - Treat pin holes on the concrete substrate prior to waterproofing<br>
        Prior to application on Light weight block / Cemboard surface<br>
        - Treat powdery substance on light weight block / cemboard surface<br><br>
        <strong>Prior to UROFIBRE application for crack line repair</strong><br>
        - Treat recurrence crack with powdery substance ended getting from the scrabbled surface on re-skim coated
        crack<br /><br />
        <strong>SOLUTIONS ON CONCRETE SHRINKAGE CRACK</strong><br />
        Immersion System for water leaking issue<br />
        - Effective and permanent solution for terminating water leaking issues on concrete shrinkage crack<br />
      </div>
      <div class="font-bold mb-5 mt-5 underline">MIXING</div>
      <div>
        <Strong>FOR SURFACE PREPARATION</Strong>
        <p>- Ready to use from opened cap, do not dilute with water, do not add any admixture.</p>
      </div>
      <div class="font-bold mb-5 mt-5 underline">APPLICATION</div>
      <div>
        <p><strong>SURFACE PREPARATION</strong><br />
          Apply UROPRIME with roller, brush or sprayer on the substrate surface. Leave it to dry for approximately 15
          minutes before next application.<br /><br />
          <strong>FOR SOLUTIONS ON CONCRETE SHRINKAGE CRACK</strong><br />
          Prepare a media of immersion system, eg: Pipe Sleeve with socket or modified bucket. In reliable on the
          gravity pressure given from the media/equipment of immersion, resulting the time taken for UROPRIME to fully
          penetrate and immerse into the concrete shrinkage crack, in order to terminate water leaking issues from
          concrete slab to soffit area.
        </p>
      </div>
      <div class="font-bold mb-5 mt-5 underline">LIMITATIONS</div>
      <div>
        <p>- Limited to the effective time of <strong>UROPRIME</strong> is within 10 days.<br />
          - Do not commence any construction work if the applied coating has been passed the effective time.<br />
          - Do not apply on stained mould oil surface.
        </p>
      </div>
      <img
        class="mx-auto mt-5 mb-10 group-hover:scale-105 transition-transform duration-500 ease-in-out h-auto w-full top-0 start-0 rounded-xl"
        src="../assets/mixingstep1-3.png" alt="Image Description">
      
      <div class="font-bold mb-5 mt-5 underline">CLEANING</div>
      <div>
        Wash and clean the tools with water when UROPRIME is still wet.
      </div>
      <div class="font-bold mb-5 mt-5 underline">HEALTH & SAFETY</div>
      <div>
        This product is non-toxic. In cases of allergic and sensitive skin contact, flush and clean with water. If severe
        medical reaction happened please seek for professional help.
      </div>
      <div class="font-bold mb-5 mt-8 underline">TECHNICAL DATA</div>
      <div class="flex flex-col mt-5">
        <div class="-m-1.5 overflow-x-auto">
          <div class="p-1.5 min-w-full inline-block align-middle">
            <div class="overflow-hidden">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">PACKAGING
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">5, 10, 20 Litre / Pail</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COLOUR
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Blue</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">TOOL</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Roller, brush</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COVERAGE
                      AREA</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Approximately 18m2 –
                      22m2 / Litre (Subject to method of application, type, temperature, texture and porosity of
                      substrate)</td>
                  </tr>
                 
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">CURING
                      TIME</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Approximately 30
                      minutes (Subject to humidity and temperature)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">EFFECTIVE
                      TIME</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">10 Days</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">SHELF
                      LIFE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">12 months</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Normal, dry
                      conditions at ambient temperature. Store out of direct sunlight.</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                      TEMPERATURE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">10°C - 37°C</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">DENSITY
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">±1.05kg/litre</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-gray-400 italic mt-5">
          The information provided on this data sheet is not intended to be complete and is provided as general advice
          only. It is the user’s responsibility to ensure that the product is suitable for its intended purpose. As we
          have no control over the treatment of the product, the standard of surface preparation, or other factors, we are
          not responsible for any damages or injury, including but not limited to special or consequential damages which
          may result from the use of our products, which includes any damages or injury caused by any failure of
          performance. The information contained in this data sheet may be modified by us from time to time, without any
          notice arising from our continuous product development activities.
        </div>
      </div>
    </div>
  </div>
  <ProductListComponent />
</template>
    
<script>
import ProductListComponent from "./ProductListComponent.vue";

export default {
  name: 'UroprimePage',
  components: {
    ProductListComponent
  }
}
</script>
    