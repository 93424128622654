import { createApp } from 'vue'
import App from './App.vue'
import './styles.css'
import "preline/preline";

import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ServicesPage from './pages/ServicesPage'
import ProductPage from './pages/ProductsPage'
import InsightsPage from './pages/InsightsPage'
import ContactPage from './pages/ContactPage'
import ProjectPage from './pages/ProjectPage'
import Urobond600Maxi from './pages/Urobond600MaxiPage'
import Urobond600 from './pages/Urobond600Page'
import Urocrete from './pages/UrocretePage'
import UrofibreFibreAdhiese from './pages/UrofibreFibreAdhiesePage'
import Urohancer from './pages/UrohancerPage'
import UroPlasterBond from './pages/UroPlasterBondPage'
import UroplastPremium from './pages/UroplastPremiumPage'
import Uroprime from './pages/UroprimePage'
import VueSocialChat from 'vue-social-chat'

const routes = [
    { path: '/', component: HomePage },
    { path: '/about', component: AboutPage },
    { path: '/solutions', component: ServicesPage },
    { path: '/products', component: ProductPage },
    { path: '/project', component: ProjectPage },
    { path: '/insights', component: InsightsPage },
    { path: '/contact', component: ContactPage },
    { path: '/products/urobond600Maxi', component: Urobond600Maxi },
    { path: '/products/urobond600', component: Urobond600 },
    { path: '/products/urocrete', component: Urocrete },
    { path: '/products/urofibreFibreAdhiese', component: UrofibreFibreAdhiese },
    { path: '/products/urohancer', component: Urohancer },
    { path: '/products/uroPlasterBond', component: UroPlasterBond },
    { path: '/products/uroplastPremium', component: UroplastPremium },
    { path: '/products/uroprime', component: Uroprime }
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.afterEach((to, from, failure) => {
    if (!failure) {
        setTimeout(() => {
            window.HSStaticMethods.autoInit();
        }, 100)
    }
});

const app = createApp(App)
app.use(router)
app.use(VueSocialChat)
app.mount('#app')

