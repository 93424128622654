<template>
  <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
    <div class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      href="/products/urocrete">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-80 h-80">
          <img
            class="mx-auto group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
            src="../assets/urofibre.png" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 sm:px-0">
          <h3
            class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            UROFIBRE & FIBRE ADHESIVE
          </h3>
          <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
            <span class="font-semibold text-gray-700">Solutions for internal wall crack line</span>
            <br />
            Hair Line Crack
          </p>
          <h3
            class="text-xl underline mt-5 font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            Description
          </h3>
          <p class="mt-5">UROFIBRE is a polyester non-woven fibre best uses for surface crack line solutions. It is
            ideally for repair surface crack line appearing after finishes (eg: skim-coated, painted surface). UROFIBRE
            use conjunctionally with URO FIBRE GLUE as a bonding media for gluing application.</p>
        </div>
      </div>
    </div>

  </div>
  <div class="bg-blue-900 text-white">
    <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <h5 class="mb-5 font-bold underline">ADVANTAGES</h5>
          <div>
            - Flexibility - Soft and light fibre enable to cater minimal movement<br />
            - Elongation - Excellent strength with air-through technology<br />
            - Durability - UV and weather resistant, heat resistant and water resistant<br />
            - Aesthetic - Overcome surface crack line by the simplest way<br />
            - Simplicity - Reduces recurrence, easy to use and to apply<br />
            - Minimal cost - In replacement of conventional method by chipping and hack off the surface crack line<br />
          </div>
        </div>

        <div>
          <h5 class="mb-5 font-bold underline">SURFACE PREPARATION</h5>
          <p>All surfaces must be structurally sound, clean, dry and free from movement, dust, oil, grease, wax, paint,
            dirt, laitance, efflorescence and other loose contaminants. Apply UROPRIME on the crack line surface for
            crystallisation and to make clean of the surface.</p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:items-center lg:px-8">
      <div class="font-bold mb-5 underline">USES</div>
      <div><strong>SOLUTIONS FOR SURFACE CRACK LINE</strong><br>
        - Crack line under the beam<br />
        - Crack line between concrete column and brick wall<br />
        - Crack line of 45º (diagonal) near the window or door<br /><br />
        <strong>SOLUTIONS FOR RECURRENCE CRACK LINE</strong><br />
        - Simplest and most economical way to remedial recurrence crack line<br />
        - Scrabbled off every loose old-component on recurrence crack line, and patch back with appropriate
        component<br />
        - Emphasis on surface treatment & preparation before apply UROFIBRE, use conjunctionally with UROPRIME
        (Crystallisation Agent)<br /><br />
        <strong>SOLUTIONS FOR CONCRETE SHRINKAGE CRACK LINE</strong><br />
        - UROFIBRE method for concrete floor shrinkage crack work conjunctionally with UROMIX Immersion System
        (UROPRIME) for water leaking issue<br />
        - Effective and permanent solution for terminating water leaking issues on concrete shrinkage crack<br />
        - Apply UROFIBRE on the surface of concrete shrinkage crack with necessary surface preparation, then proceed
        with application of waterproofing membrane<br />
      </div>
      <div class="font-bold mb-5 mt-5 underline">MIXING</div>
      <div>URO FIBRE GLUE - Ready to use from opened cap, do not dilute with water, do not add any admixture, apply with
        brush.</div>
      <div class="font-bold mb-5 mt-5 underline">APPLICATION</div>
      <div><strong>SOLUTIONS FOR SURFACE CRACKLINE</strong><br />
        - Clean and remove the loosen debris and/or peeled paint from the surface crack line<br />
        - Apply UROPRIME for crystallisation the powdery surface and to make clean of the surface<br />
        - If the crack line’s void is more than 3 mm, fill up the crack line’s void with skim coat.<br />
        - Apply URO FIBRE GLUE with brush, immediately apply UROFIBRE, and brush on again URO FIBRE GLUE until
        UROFIBRE is wet enough for bonding.<br />
        - Remove excessive URO FIBRE GLUE prevent visible ‘ramp’ for following skim coat covering surface<br />
        - Leave it to dry for at least 1 hour before the application of skim coat and painting.<br />
      </div>
      <img
        class="mx-auto mt-5 mb-10 group-hover:scale-105 transition-transform duration-500 ease-in-out h-auto w-full top-0 start-0 rounded-xl"
        src="../assets/step6.png" alt="Image Description">
      <div class="font-bold mb-5 mt-5 underline">LIMITATIONS</div>
      <div>
        - Do not apply to crack line from different building foundation joint.<br />
        - Do not apply to crack line from building settlement.
      </div>
      <div class="font-bold mb-5 mt-5 underline">CLEANING</div>
      <div>
        Wash and clean with water when URO FIBRE GLUE is still wet.
      </div>
      <div class="font-bold mb-5 mt-5 underline">HEALTH & SAFETY</div>
      <div>
        This product is non-toxic. In cases of allergic and sensitive skin contact, flush and clean with water. If
        severe medical reaction happened please seek for professional help.
      </div>
      <div class="font-bold mb-5 mt-8 underline">TECHNICAL DATA (UROFIBRE ADHESIVE)</div>
      <div class="flex flex-col mt-5">
        <div class="-m-1.5 overflow-x-auto">
          <div class="p-1.5 min-w-full inline-block align-middle">
            <div class="overflow-hidden">
              <div class="font-bold">UROFIBRE</div>
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">WIDTH
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">35mm</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">ROLL
                      LENGTH
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">4870mm / 487m /
                      1600ft</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">WEIGHT
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">±30 g/m²</td>
                  </tr>

                </tbody>
              </table>
              <div class="font-bold mt-5">UROFIBRE GLUE</div>
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                      PACKAGING
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">5 Litre /Pail</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COLOUR
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Milky White</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">TOOL
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Brush</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COLOUR
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Milky White</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                      COVERAGE PROPORTIONAL
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Urofibre : Urofibre
                      Adhesive : Uroprime 1 roll : 2 pails of 5 Litre : 2 pails of 5 Litre (Subject to method of
                      application, type, temperature, texture and porosity of substrate)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">CURING
                      TIME
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Approximately 60
                      minutes (Subject to humidity and temperature)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">SHELF
                      LIFE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">12 months</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Normal, dry
                      conditions at ambient temperature. Store out of direct sunlight.</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                      TEMPERATURE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">10°C - 37°C</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-gray-400 italic mt-5">
          The information provided on this data sheet is not intended to be complete and is provided as general advice
          only. It is the user’s responsibility to ensure that the product is suitable for its intended purpose. As we
          have no control over the treatment of the product, the standard of surface preparation, or other factors, we
          are
          not responsible for any damages or injury, including but not limited to special or consequential damages which
          may result from the use of our products, which includes any damages or injury caused by any failure of
          performance. The information contained in this data sheet may be modified by us from time to time, without any
          notice arising from our continuous product development activities.
        </div>
      </div>
    </div>
  </div>
  <ProductListComponent />
</template>

<script>
import ProductListComponent from "./ProductListComponent.vue";
export default {
  name: 'UrofibreFibreAdhiesePage',
  components: {
    ProductListComponent
  }
}
</script>