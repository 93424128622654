<template>
  <section
    class="relative bg-[url(https://media.istockphoto.com/id/534500443/photo/laying-ceramic-tiles.webp?b=1&s=612x612&w=0&k=20&c=wDQLceRTXu70mpbvB0LPLUF7v9ojmqFrRpy85f6_wIk=)] bg-cover bg-center bg-no-repeat">
    <div class="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 sm:bg-gradient-to-r">
    </div>
    <div class="relative mx-auto max-w-[85rem] px-4 py-32 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="text-center sm:text-left max-lg">
        <h1 class="text-3xl font-extrabold sm:text-5xl">
          You deserve the excellence of integrated <span class="block font-extrabold text-green-700"> Contruction
            Method. </span>
        </h1>
        <p class="mt-4 max-w-lg sm:text-xl/relaxed">
          Revolutionize The Tiling & Plastering Industry
        </p>
        <div class="mt-8 flex flex-wrap gap-4 text-center cursor-pointer">
          <a @click.prevent="openPdf" target="_blank"
            class="block w-full rounded bg-green-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-green-700 focus:outline-none  active:bg-green-500 sm:w-auto">
            Broucher Download
          </a>
        </div>
      </div>
    </div>
  </section>
  <div class="overflow-hidden bg-slate-800">
    <div class="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div class="max-w-2xl w-3/4 lg:w-1/2 mb-6 sm:mb-10 md:mb-16">
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-white font-semibold">
          Embraced by companies and individuals nationwide
        </h2>
      </div>

      <div class="mt-20 grid gap-6 grid-cols-2 sm:gap-12 lg:grid-cols-4 lg:gap-8">
        <div>
          <h4 class="text-lg sm:text-xl font-semibold text-white">Client Retention Rate</h4>
          <p class="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-green-600">100%</p>
          <p class="mt-1 text-gray-400">Returning client.</p>
        </div>
        <div>
          <h4 class="text-lg sm:text-xl font-semibold text-white">Fulfilment Rate</h4>
          <p class="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-green-600">100%</p>
          <p class="mt-1 text-gray-400">Delivery successful </p>
        </div>
        <div>
          <h4 class="text-lg sm:text-xl font-semibold text-white">Completed Project</h4>
          <p class="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-green-600">500+</p>
          <p class="mt-1 text-gray-400">In collaboration with Main/Sub con</p>
        </div>
        <div>
          <h4 class="text-lg sm:text-xl font-semibold text-white">Satisfied Customer</h4>
          <p class="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-green-600">100%</p>
          <p class="mt-1 text-gray-400">As Per 2023</p>
        </div>
      </div>

      <div class="absolute bottom-0 end-0 transform lg:translate-x-32" aria-hidden="true">
        <svg class="w-40 h-auto sm:w-72" width="1115" height="636" viewBox="0 0 1115 636" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.990203 279.321C-1.11035 287.334 3.68307 295.534 11.6966 297.634L142.285 331.865C150.298 333.965 158.497 329.172 160.598 321.158C162.699 313.145 157.905 304.946 149.892 302.845L33.8132 272.418L64.2403 156.339C66.3409 148.326 61.5475 140.127 53.5339 138.026C45.5204 135.926 37.3213 140.719 35.2207 148.733L0.990203 279.321ZM424.31 252.289C431.581 256.26 440.694 253.585 444.664 246.314C448.635 239.044 445.961 229.931 438.69 225.96L424.31 252.289ZM23.0706 296.074C72.7581 267.025 123.056 230.059 187.043 212.864C249.583 196.057 325.63 198.393 424.31 252.289L438.69 225.96C333.77 168.656 249.817 164.929 179.257 183.892C110.144 202.465 54.2419 243.099 7.92943 270.175L23.0706 296.074Z"
            fill="currentColor" class="fill-green-500" />
          <path
            d="M451.609 382.417C446.219 388.708 446.95 398.178 453.241 403.567L555.763 491.398C562.054 496.788 571.524 496.057 576.913 489.766C582.303 483.474 581.572 474.005 575.281 468.615L484.15 390.544L562.222 299.413C567.612 293.122 566.881 283.652 560.59 278.263C554.299 272.873 544.829 273.604 539.44 279.895L451.609 382.417ZM837.202 559.655C841.706 566.608 850.994 568.593 857.947 564.09C864.9 559.586 866.885 550.298 862.381 543.345L837.202 559.655ZM464.154 407.131C508.387 403.718 570.802 395.25 638.136 410.928C704.591 426.401 776.318 465.66 837.202 559.655L862.381 543.345C797.144 442.631 718.724 398.89 644.939 381.709C572.033 364.734 504.114 373.958 461.846 377.22L464.154 407.131Z"
            fill="currentColor" class="fill-red-500" />
          <path
            d="M447.448 0.194357C439.203 -0.605554 431.87 5.43034 431.07 13.6759L418.035 148.045C417.235 156.291 423.271 163.623 431.516 164.423C439.762 165.223 447.095 159.187 447.895 150.942L459.482 31.5025L578.921 43.0895C587.166 43.8894 594.499 37.8535 595.299 29.6079C596.099 21.3624 590.063 14.0296 581.818 13.2297L447.448 0.194357ZM1086.03 431.727C1089.68 439.166 1098.66 442.239 1106.1 438.593C1113.54 434.946 1116.62 425.96 1112.97 418.521L1086.03 431.727ZM434.419 24.6572C449.463 42.934 474.586 81.0463 521.375 116.908C568.556 153.07 637.546 187.063 742.018 200.993L745.982 171.256C646.454 157.985 582.444 125.917 539.625 93.0974C496.414 59.978 474.537 26.1903 457.581 5.59138L434.419 24.6572ZM742.018 200.993C939.862 227.372 1054.15 366.703 1086.03 431.727L1112.97 418.521C1077.85 346.879 956.138 199.277 745.982 171.256L742.018 200.993Z"
            fill="currentColor" class="fill-blue-600" />
        </svg>
      </div>

    </div>
  </div>
  <div class="overflow-hidden bg-slate-100">
    <div class="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 mx-auto">
      <div class="max-w-2xl lg:w-1/2 mb-6 sm:mb-10 md:mb-16">
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black font-bold">
          Story of Our Company
        </h2>
      </div>
      <p class="mb-2 sm:text-xl/relaxed">
        Welcome to Uromix, your premier destination for top-quality bonding solutions in Malaysia. With a steadfast
        commitment to excellence, innovation, and sustainability, we take pride in offering a comprehensive range of
        bonding agents and tile adhesives tailored to meet the diverse needs of our clients.
      </p>
      <p class="mb-2 sm:text-xl/relaxed">
        At Uromix, we understand the critical role that bonding agents play in ensuring the durability and longevity of
        construction projects. Whether you're in need of plastering bonding agents, tiling bonding agents, or tile
        adhesives, we have you covered. </p>
      <p class="mb-2 sm:text-xl/relaxed">
        Quality and reliability are at the forefront of everything we do. That's why all our products undergo rigorous
        testing and adhere to strict quality standards. With Uromix, you can rest assured that you're investing in
        products that not only deliver exceptional performance but also contribute to a greener, more sustainable
        future. </p>
      <p class="mb-2 sm:text-xl/relaxed">
        As a leading bonding agent manufacturer and supplier in Malaysia, we take pride in our ability to provide
        tailored solutions to meet the unique requirements of each project. Whether you're a contractor looking for the
        best bonding agents for your tiling projects or a distributor seeking reliable products to add to your
        inventory, we have the expertise and resources to meet your needs. </p>
      <p class="mb-2 sm:text-xl/relaxed">
        In addition to our wide range of products, we also offer expert guidance and support every step of the way. Our
        team of seasoned professionals is always on hand to provide technical assistance, recommend the best products
        for your specific application, and ensure that your projects are completed with utmost precision and efficiency.
      </p>
      <p class="mb-2 sm:text-xl/relaxed">
        At Uromix, we don't just sell products; we build relationships. We take pride in being your trusted partner in
        bonding solutions, working tirelessly to exceed your expectations and deliver unparalleled value every time.
        Experience the Uromix difference today and discover why we're the preferred choice for contractors,
        distributors, and construction professionals across Malaysia.</p>
    </div>
  </div>
  <div class="overflow-hidden bg-slate-100">
    <div class="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div class="max-w-2xl w-3/4 lg:w-1/2 mb-6 sm:mb-10 md:mb-16">
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black font-bold">
          UROMIX Tiling & Plastering Solution
        </h2>
      </div>
      <div class="grid sm:grid-cols-2 lg:grid-cols-2 gap-6">
        <div class="aspect-video">
          <iframe src="https://www.youtube.com/embed/M3U9d0Z88lE?si=WrD4UH9dCX4CZW6K" frameborder="0"
            className="w-full h-full rounded-lg shadow-xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <div class="aspect-video">
          <iframe src="https://www.youtube.com/embed/IYlqziJkpsY?si=eJTFQV3fsgMq9kBH" frameborder="0"
            className="w-full h-full rounded-lg shadow-xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
  <div class="overflow-hidden bg-white">
    <div class="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div class="max-w-3xl mb-6 sm:mb-10 md:mb-16">
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black font-bold">
          UROMIX Products Application Methods
        </h2>
      </div>
      <p class="mb-[50px] sm:text-xl/relaxed">
        Uromix is revolutionizing the tiling and plastering industry with expert advice and innovative solutions. In the
        fast-paced world of construction, we lead the way by emphasizing both speed and quality. As pioneers in bonding
        solutions, we take pride in offering unique and tailored advice to meet our clients' specific needs. Join us as
        we redefine excellence in construction solutions!
      </p>
      <div class="grid sm:grid-cols-2 lg:grid-cols-2 gap-6">
        <div>
          <img class="object-cover w-full h-auto" src="../assets/rc1.jpg" alt="" />
        </div>
        <div>
          <img class="object-cover w-full h-auto" src="../assets/rc2.jpg" alt="" />
        </div>
        <div>
          <img class="object-cover w-full h-auto" src="../assets/rc3.jpg" alt="" />
        </div>
        <div>
          <img class="object-cover w-full h-auto" src="../assets/rc4.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
    <div
      class="inset-y-0 top-0 right-0 z-0 w-full max-w-[85rem] px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
      <svg class="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block" viewBox="0 0 100 100"
        fill="currentColor" preserveAspectRatio="none slice">
        <path d="M50 0H100L50 100H0L50 0Z"></path>
      </svg>
      <img class="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-16 lg:h-full"
        src="../assets/pexels-thirdman-7256442.jpg" alt="" />
    </div>
    <div class="relative flex flex-col items-start w-full max-w-[85rem] px-4 sm:px-8 mx-auto">
      <div class="mb-5 lg:my-10 lg:max-w-lg lg:pr-1">
        <p class="pr-5 text-base text-gray-400 mb-5 md:text-xl">
          Research & Development
        </p>
        <h1 class="md:text-lg">Pioneering Sustainability: From environmentally friendly to Green Excellence in Bonding
          Agents</h1>
        <p class="mt-4 pr-5 mb-5 text-sm text-gray-700">
          At Uromix, we are proud to announce a significant milestone in our journey towards sustainability: the
          environmentally friendly of our bonding agents. This certification not only underscores our unwavering
          commitment to
          quality but also serves as a stepping stone towards our ultimate goal of producing Green Certified Bonding
          Agents. Join us as we embark on this transformative journey, leading the way in sustainable chemical
          solutions.
        </p>
        <h1 class="md:text-lg">Our environmentally friendly Bonding Agents: A Testament to Quality and Reliability</h1>
        <p class="mt-4 pr-5 mb-5 text-sm text-gray-700">
          The attainment of environmentally friendly for our bonding agents is a testament to our dedication to excellence.
          This certification, recognized for its rigorous standards and stringent testing processes, assures our
          customers of the superior quality and reliability of our products. It reaffirms our position as a trusted
          provider of bonding solutions that meet the highest industry benchmarks.
        </p>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomePage',
  methods: {
    openPdf() {
      window.open("http://uromix.com.my/assets/product.pdf")
    }
  }
}
</script>