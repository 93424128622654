<template>
  <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
    <div class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      href="/products/urocrete">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-80 h-80">
          <img
            class="mx-auto group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
            src="../assets/urocrete.png" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 sm:px-0">
          <h3
            class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            UROCRETE™
          </h3>
          <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
            <span class="font-semibold text-gray-700">MULTI-PURPOSE BONDING AGENT</span>
            <br />
            For Tiles Installation
          </p>
          <h3
            class="text-xl underline mt-5 font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            Description
          </h3>
          <p class="mt-5">UROCRETE™ is designed as a Multi-Purpose Bonding Agent most suitable for Tiles Installation,
            Tiles on tiles installation, and others unconventional application which requires two surfaces bond together
            with the binder of UROHANCER cementitious mortar.</p>
          <p class="mt-5">UROCRETE™ is a greenish water-based emulsion admixture with exceptionally high bonding ability
            when combined with Ordinary Portland Cement.</p>
        </div>
      </div>
    </div>

  </div>
  <div class="bg-green-700 text-white">
    <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <h5 class="mb-5 font-bold underline">ADVANTAGES</h5>
          <div>
            <div class="font-semibold">For Tiles Installation</div>
            - Improves flexibility and reduces deflection on tiles eg: shopping mall.<br />
            - Ensure no debonding and minimises remedial work.<br />
            - Enormous time saving & cost saving over conventional method.<br />
            - High bonding performance even laying tiles on RC/Shear wall, glass, plywood, or other unconventional
            surfaces.<br />
            - Allow tiles installation directly on RC/Shear wall, eliminates extra cost on plastering.<br />
            - Simplicity - Apply with roller at the back of the tiles eg: non-porousity tiles.

            <div class="font-semibold mt-5">For Marble Installation</div>
            - Suitable for marble installation without removing epoxy netting, apply on the receiving surface of
            adhesive,
            provided the epoxy netting is deemed to be still durable.
          </div>
        </div>

        <div>
          <h5 class="mb-5 font-bold underline">SURFACE PREPARATION</h5>
          <p>All surfaces must be structurally sound, clean, dry and free from movement, dust, oil, grease, wax, paint,
            dirt, laitance, efflorescence and other loose contaminants.</p>
          <div>
            <div class="font-semibold mt-5">For Tiles Installation</div>
            Ensure the back of the tiles is free of dirt, wax, grease, oil, water and other contaminants. Painted
            surfaces must be chipped with an axe to expose at least 65% of plastered surface and must be primed with
            UROPRIME before tiles installation.
            <div class="font-semibold mt-5">For Marble Installation</div>
            Ensure the epoxy netting is deemed to be still durable.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:items-center lg:px-8">
      <div class="font-bold mb-5 underline">USES</div>
      <div class="font-semibold">For Tiles Installation</div>
      <div>UROCRETE™ is used with UROHANCER - an additive to add with cement mortar to produce tile bedding, especially
        for
        for non-porousity tiles installation. E.g.: Porcelain, Homogenous, Marble, Granite, Stone etc. In replacement of
        ‘Scratching/Buttering coat’ at tile base, UROCRETE™ bonding agent application can be enormously cost and time
        saving with improved bonding ability.
        *Strongly recommended for internal use. Please refer method statement from P.I.C for external use.</div>
      <div class="font-bold mb-5 mt-5 underline">MIXING</div>
      <div>Stir well before use, do not dilute with water, do not add any admixture.</div>
      <div class="font-bold mb-5 mt-5 underline">APPLICATION</div>
      <div>Apply with brush or roller, ensuring an even distribution. Minimises overlapping of material on same applying
        area. Leave it to dry for approximately 30 minutes before next application.
      </div>
      <img
        class="mx-auto mt-5 mb-10 group-hover:scale-105 transition-transform duration-500 ease-in-out h-auto w-full top-0 start-0 rounded-xl"
        src="../assets/mixingstep1-3.png" alt="Image Description">
      <div class="font-bold mb-5 mt-5 underline">LIMITATIONS</div>
      <div>
        - Limited to the effective time of UROCRETE™ is within 7days.<br />
        - Do not commence any construction work if the applied coating has been passed the effective time.<br />
        - Do not apply to ponded areas such as swimming pool and water feature wall.<br />
      </div>
      <div class="font-bold mb-5 mt-5 underline">CLEANING</div>
      <div>
        Wash and clean with water when UROCRETE™ is still wet.
      </div>
      <div class="font-bold mb-5 mt-5 underline">HEALTH & SAFETY</div>
      <div>
        This product is non-toxic. In cases of allergic and sensitive skin contact, flush and clean with water. If
        severe
        medical reaction happened please seek for professional help.
      </div>
      <div class="font-bold mb-5 mt-8 underline">TECHNICAL DATA</div>
      <div class="flex flex-col mt-5">
        <div class="-m-1.5 overflow-x-auto">
          <div class="p-1.5 min-w-full inline-block align-middle">
            <div class="overflow-hidden">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                      PACKAGING</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">5, 15, 19 Litre /
                      Pail</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COLOUR
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Green</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                      COVERAGE AREA</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Approximately 18m2
                      – 22m2 / Litre (Subject to method of application, type, temperature, texture and porosity of
                      substrate)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">TOOL
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Roller or brush
                    </td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">CURING
                      TIME</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Approximately 15
                      minutes (Subject to humidity and temperature)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                      EFFECTIVE TIME</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">7 Days</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">SHELF
                      LIFE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">12 months</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Normal, dry
                      conditions at ambient temperature. Store out of direct sunlight.</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                      TEMPERATURE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">10°C - 37°C</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">DENSITY
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">±1.05kg/litre</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-gray-400 italic mt-5">
          The information provided on this data sheet is not intended to be complete and is provided as general advice
          only. It is the user’s responsibility to ensure that the product is suitable for its intended purpose. As we
          have no control over the treatment of the product, the standard of surface preparation, or other factors, we
          are not responsible for any damages or injury, including but not limited to special or consequential damages
          which may result from the use of our products, which includes any damages or injury caused by any failure of
          performance. The information contained in this data sheet may be modified by us from time to time, without any
          notice arising from our continuous product development activities.
        </div>
      </div>
    </div>
  </div>
  <ProductListComponent />
</template>

<script>
import ProductListComponent from "./ProductListComponent.vue";
export default {
  name: 'UrocretePage',
  components: {
    ProductListComponent
  }
}
</script>