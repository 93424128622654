<template>
  <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
    <div class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      href="/products/urocrete">
      <div class="sm:flex">
        <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-80 h-80">
          <img
            class="mx-auto group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
            src="../assets/urobond600.png" alt="Image Description">
        </div>

        <div class="grow mt-4 sm:mt-0 sm:ms-6 sm:px-0">
          <h3
            class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            UROBOND 600™
          </h3>
          <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
            <span class="font-semibold text-gray-700">Tile Adhesive Additive (Site-Mix)</span>
            <br />
            For Tiles Installation with Ordinary Portland Cement (OPC) & Washed Fine Sand
          </p>
          <h3
            class="text-xl underline mt-5 font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            Description
          </h3>
          <p class="mt-5">UROBOND 600™ is formulated as a tile adhesive additive to add with OPC cement mortar and washed
            fine sand. It is designed to meet the standard of Tile Adhesive in Class C2 to cater non-porosity tiles eg:
            Porcelain, Homogenous.</p>
        </div>
      </div>
    </div>

  </div>
  <div class="bg-red-500 text-white">
    <div class="relative mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="grid grid-cols-1 gap-4">
        <div>
          <h5 class="mb-5 font-bold underline">ADVANTAGES</h5>
          <div>
            <div class="font-semibold">For Tiles Installation</div>
            - Easy handling of material - reduces manpower and time taken on logistics<br />
            - Improves tensile strength of OPC cement mortar + washed fine sand<br />
            - Extended OPC cement mortar open time / prolonged life span<br />
            - High tensile pulling strength for big sizes of tiles eg: Porcelain, Homogenous<br />
            - Enormous cost saving, in replacement of conventional 25kg pre-mix tile adhesive<br />
            <br />
            <div class="font-semibold">For Defects and solutions</div>
            - Simplicity - produce high tensile mortar with easy accessibility<br />
          </div>
        </div>


      </div>
    </div>
  </div>
  <div>
    <div class="mx-auto max-w-[85rem] px-4 py-10 sm:px-6 lg:items-center lg:px-8">
      <div class="font-bold mb-5 underline">USES</div>
      <div class="font-semibold">For Tiles Installation</div>
      <div>
        -UROBOND 600™ is a tile adhesive additive to add with OPC cement mortar and washed fine sand as a tile bedding
        adhesive.<br />
        -UROBOND 600™ is designed for non-porosity tiles installation eg: Porcelain, Homogenous. Ideally for both
        interior and exterior area.<br />
        -UROBOND600™ is a replacement of conventional pre-mix Tile Adhesive, enormously cost saving with excellent
        tensile strength.<br /><br />
        <strong>For Defects and Solutions</strong><br />
        - Patching mortar at joint between column and brick wall to prevent crack line occurrence after finishes
        (plaster and painting)<br />
        - Produce high tensile mortar for honeycomb repair, or others defects required patch up on old concrete
        surface<br />
        - Add into cement screed/render mixture for better water retention<br />
      </div>
      <div class="font-bold mb-5 mt-5 underline">SURFACE PREPARATION</div>
      <div>
        <Strong>For Tiles Installation</Strong>
        <p>
          - Ensure the back of the tiles is free of dirt, wax, grease, oil, water and other contaminants.<br />
          - Ensure the surface of substrate receiving <strong>UROBOND 600™</strong> adhesive mortar is sound, clean, dry
          and free
          from movement, dust, oil, grease, wax paint, dirt, laitance, efflorescence and other loose contaminants.<br />
          - Cementitious substrate must not be subject to shrinkage after tile installation. Therefore, in mild weather,
          rendered surfaces must be allowed to cure for at least 28 days.<br />
          - Pre-wet the substrate before application, do not allow water ponding prior to application of notched trowel
          of <strong>UROBOND 600™</strong> admixture.<br />
          - Gypsum substrate, anhydrite and porous surfaces must be perfectly dry, sufficient hard and free from dust.
          They must be treated with <strong>UROPRIME</strong>.<br />
        </p>
      </div>
      <div class="font-bold mb-5 mt-5 underline">MIXING</div>
      <div>
        <p>
          - Prepare a 20L kind of bucket/pail/container, add in 7-8 litre of water, add in <strong>UROBOND 600™</strong>
          200g, stir it
          with electrical mixer, add in 12.5kg of Ordinary Portland Cement (OPC), 12,5kg of washed fine sand, and stir
          it again with electrical mixer until a lump-free, creamy consistency admixture is achieved.<br />
          - Allow the mix to stand for 5 minutes and remix prior to use. Mix lasts approximately 1-2 hours, depend on
          the temperature.
        </p>
      </div>
      <div class="font-bold mb-5 mt-5 underline">APPLICATION</div>
      <p>
        - Spread the adhesive onto the substrate. The choice of the trowel should be governed by the size of the tiles, depth of knobs, grooves or ridges on the back of the tiles and the evenness of the substrate.<br/>
        - Apply ‘Scratching/Buttering Coat’ of UROBOND 600™ adhesive mortar at the back of the tiles/receiving surface of the tiles with sponge trowel, immediately notch trowel on floor and lay following tiles for better adhesion and support occurs especially for non-porous tiles. Do not attempt to leave ‘Scratching/Buttering Coat’ overnight.<br/>
        - Ensure there are no voids under the tiles.<br/>
        - Do not allow the adhesive to skin over prior to tilling. If not, remove the adhesive or re-trowel the adhesive.<br/>
        - Allow 24 hours curing before grouting for floor and allow 4-8 hours curing before grouting for wall.<br/>
        - Floor are set to light foot traffic after approximately 24 hours.<br/>
        - Leave minimum joints of 2mm for wall tiling & 3mm for floor tiling.<br/>

      </p>
      <div class="font-bold mb-5 mt-5 underline">LIMITATIONS</div>
      <div>
        <p>Tiling installed with UROBOND 600™ must not be washed or exposed to rain for at least 24 hours and must be protected from frost and strong sun for at least 5-7 days.
        </p>
      </div>
      <img
        class="mx-auto mt-5 mb-10 group-hover:scale-105 transition-transform duration-500 ease-in-out h-auto w-full top-0 start-0 rounded-xl"
        src="../assets/step8.png" alt="Image Description">

      <div class="font-bold mb-5 mt-5 underline">CLEANING</div>
      <div>
        Wash and clean the tools and bucket with water when the mortar is still wet.
      </div>
      <div class="font-bold mb-5 mt-5 underline">HEALTH & SAFETY</div>
      <div>
        This product is non-toxic. In cases of allergic and sensitive skin contact, flush and clean with water. If severe medical reaction happened, please seek for professional help.
      </div>
      <div class="font-bold mb-5 mt-8 underline">TECHNICAL DATA</div>
      <div class="flex flex-col mt-5">
        <div class="-m-1.5 overflow-x-auto">
          <div class="p-1.5 min-w-full inline-block align-middle">
            <div class="overflow-hidden">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <tbody>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">PACKAGING
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">50 Bag / Carton (200 gram / bag)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">COLOUR
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">White powder</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">MIXING RATIO	</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Urobond 600™ (200gram) : 12.5kg Cement : 12.5kg Washed Fine Sand</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">CONSUMPTION	
                      AREA</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">1.2kg/m²/mm</td>
                  </tr>

                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">TENSILE ADHESION STRENGTH	</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">	≤1.0 N/mm²</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">OPEN
                      TIME</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">> 30 minutes (Subject to humidity and temperature)</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">SHELF
                      LIFE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">18 months</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">Normal, dry
                      conditions at ambient temperature. Store out of direct sunlight.</td>
                  </tr>
                  <tr class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">STORAGE
                      TEMPERATURE</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">10°C - 37°C</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-gray-400 italic mt-5">
          The information provided on this data sheet is not intended to be complete and is provided as general advice
          only. It is the user’s responsibility to ensure that the product is suitable for its intended purpose. As we
          have no control over the treatment of the product, the standard of surface preparation, or other factors, we are
          not responsible for any damages or injury, including but not limited to special or consequential damages which
          may result from the use of our products, which includes any damages or injury caused by any failure of
          performance. The information contained in this data sheet may be modified by us from time to time, without any
          notice arising from our continuous product development activities.
        </div>
      </div>
    </div>
  </div>
  <ProductListComponent />
</template>
    
<script>
import ProductListComponent from "./ProductListComponent.vue";
export default {
  name: 'Urobond600Page',
  components: {
    ProductListComponent
  }
}
</script>
    