<template>
  <div class="relative overflow-hidden">
    <!-- Gradients -->
    <div aria-hidden="true" class="flex absolute start-1/2 transform -translate-x-1/2">
      <div
        class="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem] dark:from-violet-900/50 dark:to-purple-900">
      </div>
      <div
        class="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem] dark:from-indigo-900/70 dark:via-indigo-900/70 dark:to-blue-900/70">
      </div>
    </div>
    <!-- End Gradients -->

    <div class="relative z-10">
      <section
        class="relative bg-[url(https://media.istockphoto.com/id/534500443/photo/laying-ceramic-tiles.webp?b=1&s=612x612&w=0&k=20&c=wDQLceRTXu70mpbvB0LPLUF7v9ojmqFrRpy85f6_wIk=)] bg-cover bg-center bg-no-repeat">
        <div class="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 sm:bg-gradient-to-r">
        </div>
        <div class="relative mx-auto max-w-[85rem] px-4 py-32 sm:px-6 lg:flex lg:items-center lg:px-8">
          <div class="text-center sm:text-left max-lg">
            <h1 class="text-3xl font-extrabold sm:text-5xl">
              You deserve the excellence of integrated <span class="block font-extrabold text-green-700"> Contruction
                Method. </span>
            </h1>
            <p class="mt-4 max-w-lg sm:text-xl/relaxed">
              Revolutionize The Tiling & Plastering Industry
            </p>
            <div class="mt-8 flex flex-wrap gap-4 text-center cursor-pointer">
              <a @click.prevent="openPdf" target="_blank"
                class="block w-full rounded bg-green-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-green-700 focus:outline-none  active:bg-green-500 sm:w-auto">
                Broucher Download
              </a>
            </div>
          </div>
        </div>
      </section>
      <!-- Card Blog -->
      <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div class="max-w-2xl text-center mx-auto mb-10 lg:mb-14">
          <h2 class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">Our Products</h2>
          <p class="mt-1 text-gray-600 dark:text-gray-400">Great company always come with great products.</p>
        </div>
        <!-- Grid -->
        <div class="grid grid-cols-2 lg:gap-y-16 sm:gap-10 gap-2">
          <!-- Card -->
          <a class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="/products/urocrete">
            <div class="sm:flex">
              <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <img
                  class="group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
                  src="../assets/urocrete.png" alt="Image Description">
              </div>

              <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3
                  class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  UROCRETE™
                </h3>
                <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
                  <span class="font-semibold text-gray-700">MULTI-PURPOSE BONDING AGENT</span>
                  <br />
                  For Tiles Installation
                </p>
                <p class="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  Read more
                  <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>
          <!-- End Card -->

          <!-- Card -->
          <a class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="/products/urohancer">
            <div class="sm:flex">
              <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <img
                  class="group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 object-cover rounded-xl"
                  src="../assets/urohancer.png" alt="Image Description">
              </div>

              <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3
                  class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  UROHANCER
                </h3>
                <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
                  <span class="font-semibold text-gray-700">CEMENT ENHANCER/CEMENT ADDITIVE</span>
                  <br />
                  For Tiles Installation with Ordinary Portland Cement
                </p>
                <p class="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  Read more
                  <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>
          <!-- End Card -->

          <!-- Card -->
          <a class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="/products/uroprime">
            <div class="sm:flex">
              <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <img
                  class="group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 object-cover rounded-xl"
                  src="../assets/uroprime.png" alt="Image Description">
              </div>

              <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3
                  class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  UROPRIME
                </h3>
                <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
                  <span class="font-semibold text-gray-700">MULTI-PURPOSE POLYMER-BASED PRIMER</span>
                  <br />
                  Crystallisation Agent & Solutions for Concrete Shrinkage Crack
                </p>
                <p class="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  Read more
                  <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>
          <!-- End Card -->

          <!-- Card -->
          <a class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="/products/uroPlasterBond">
            <div class="sm:flex">
              <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <img
                  class="group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
                  src="../assets/uroplaster.png" alt="Image Description">
              </div>

              <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3
                  class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  URO PLASTER BOND
                </h3>
                <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
                  <span class="font-semibold text-gray-700">PLASTERING BONDING AGENT</span>
                  <br />
                  For Light Weight Blocks, RC & Shear Wall Plastering
                </p>
                <p class="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  Read more
                  <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>
          <!-- End Card -->

          <!-- Card -->
          <a class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="/products/uroplastPremium">
            <div class="sm:flex">
              <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <img
                  class="group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
                  src="../assets/uroplast premium.png" alt="Image Description">
              </div>

              <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3
                  class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  UROPLAST PREMIUM
                </h3>
                <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
                  <span class="font-semibold text-gray-700">PLASTERING & MORTAR ADDITIVES</span>
                  <br />
                  For clay brickwork and plastering
                </p>
                <p class="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  Read more
                  <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>
          <!-- End Card -->

          <!-- Card -->
          <a class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="/products/urofibreFibreAdhiese">
            <div class="sm:flex">
              <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <img
                  class="group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
                  src="../assets/urofibre.png" alt="Image Description">
              </div>

              <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3
                  class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  UROFIBRE & FIBRE ADHESIVE
                </h3>
                <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
                  <span class="font-semibold text-gray-700">SOLUTIONS FOR INTERNAL WALL CRACK LINE</span>
                  <br />
                  Hair Line Crack
                </p>
                <p class="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  Read more
                  <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>
          <!-- End Card -->

          <!-- Card -->
          <a class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="/products/urobond600Maxi">
            <div class="sm:flex">
              <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <img
                  class="group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
                  src="../assets/urobondmaxi.png" alt="Image Description">
              </div>

              <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3
                  class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  UROBOND 600 MAXI
                </h3>
                <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
                  <span class="font-semibold text-gray-700">TILE ADHESIVE (PRE-MIX)</span>
                  <br />
                  PREMIUM GRADE Cementitious Tile Adhesive
                </p>
                <p class="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  Read more
                  <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>
          <!-- End Card -->

          <!-- Card -->
          <a class="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="/products/urobond600">
            <div class="sm:flex">
              <div class="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <img
                  class="group-hover:scale-105 transition-transform duration-500 ease-in-out h-full sm:w-auto sm:h-full absolute top-0 start-0 rounded-xl"
                  src="../assets/urobond600.png" alt="Image Description">
              </div>

              <div class="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3
                  class="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  UROBOND 600™
                </h3>
                <p class="mt-3 text-gray-500 dark:text-gray-400 text-sm sm:text-base">
                  <span class="font-semibold text-gray-700">TILE ADHESIVE ADDITIVE (SITE-MIX)</span>
                  <br />
                  For Tiles Installation with Ordinary Portland Cement (OPC) Portland Cement (OPC) & Washed Fine Sand
                </p>
                <p class="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  Read more
                  <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>
          <!-- End Card -->
        </div>
        <!-- End Grid -->
      </div>
      <div class="overflow-hidden bg-white">
        <div class="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div class="max-w-3xl mb-6 sm:mb-10 md:mb-16">
            <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black font-bold">
              UROMIX Products Application Methods
            </h2>
          </div>
          <p class="mb-[50px] sm:text-xl/relaxed">
            Uromix is revolutionizing the tiling and plastering industry with expert advice and innovative solutions. In
            the fast-paced world of construction, we lead the way by emphasizing both speed and quality. As pioneers in
            bonding solutions, we take pride in offering unique and tailored advice to meet our clients' specific needs.
            Join us as we redefine excellence in construction solutions!
          </p>
          <div class="grid sm:grid-cols-2 lg:grid-cols-2 gap-6">
            <div>
              <img class="object-cover w-full h-auto" src="../assets/rc1.jpg" alt="" />
            </div>
            <div>
              <img class="object-cover w-full h-auto" src="../assets/rc2.jpg" alt="" />
            </div>
            <div>
              <img class="object-cover w-full h-auto" src="../assets/rc3.jpg" alt="" />
            </div>
            <div>
              <img class="object-cover w-full h-auto" src="../assets/rc4.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-hidden bg-slate-100">
        <div class="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <h2 className="sm:max-w-md lg:max-w-2xl text-black w-full font-bold text-2xl md:text-3xl mb-10">
            Video Gallery
          </h2>
          <div class="grid sm:grid-cols-2 lg:grid-cols-2 gap-6">
            
            <div class="aspect-video">
              <iframe src="https://www.youtube.com/embed/M3U9d0Z88lE?si=WrD4UH9dCX4CZW6K" frameborder="0"
                className="w-full h-full rounded-lg shadow-xl"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
            <div class="aspect-video">
              <iframe src="https://www.youtube.com/embed/U8uxDXxU70Q?si=bfLnUfnHYPTtzsNZ" frameborder="0"
                className="w-full h-full rounded-lg shadow-xl"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
            <div class="aspect-video">
              <iframe src="https://www.youtube.com/embed/IYlqziJkpsY?si=eJTFQV3fsgMq9kBH" frameborder="0"
                className="w-full h-full rounded-lg shadow-xl"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
            <div class="aspect-video">
              <iframe src="https://www.youtube.com/embed/GDDJX367ctU?si=l6Jj-bwyQQZ0hUeC" frameborder="0"
                className="w-full h-full rounded-lg shadow-xl"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
            <div class="aspect-video">
              <iframe src="https://www.youtube.com/embed/H7qX6pFfXFw?si=RdklXnxxR3kFzvQR" frameborder="0"
                className="w-full h-full rounded-lg shadow-xl"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Card Blog -->
</template>

<script>
export default {
  name: 'ProductPage',
  methods: {
    openPdf() {
      window.open("http://uromix.com.my/assets/product.pdf")
    }
  }
}
</script>