<template>
  <section
    class="relative bg-[url(https://media.istockphoto.com/id/534500443/photo/laying-ceramic-tiles.webp?b=1&s=612x612&w=0&k=20&c=wDQLceRTXu70mpbvB0LPLUF7v9ojmqFrRpy85f6_wIk=)] bg-cover bg-center bg-no-repeat">
    <div class="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 sm:bg-gradient-to-r">
    </div>
    <div class="relative mx-auto max-w-[85rem] px-4 py-32 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="text-center sm:text-left max-lg">
        <h1 class="text-3xl font-extrabold sm:text-5xl">
          You deserve the excellence of integrated <span class="block font-extrabold text-green-700"> Contruction Method. </span>
        </h1>
        <p class="mt-4 max-w-lg sm:text-xl/relaxed">
          Revolutionize The Tiling & Plastering Industry
        </p>
        <div class="mt-8 flex flex-wrap gap-4 text-center cursor-pointer">
          <a @click.prevent="openPdf" target="_blank"
            class="block w-full rounded bg-green-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-green-700 focus:outline-none  active:bg-green-500 sm:w-auto">
            Broucher Download
          </a>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== Services Section Start -->
  <section class="pb-5 pt-5 dark:bg-dark">
    <div class="justify-center flex-1 max-w-[85rem] py-4 mx-auto lg:py-6 md:px-6">
      <div class=" flex flex-wrap">
        <div class="w-full px-4">
          <div class="mx-auto mb-12 max-w-[50rem] text-center lg:mb-10">
            <span class="text-primary mb-2 block text-lg font-semibold">
              Our Services
            </span>
            <h2 class="text-dark dark:text-white mb-3 text-3xl leading-[1.2] font-bold sm:text-4xl md:text-[40px]">
              What We Offer
            </h2>
            <p class="text-body-color text-base dark:text-dark-6">
              Take a closer look at our projects. Learn about the creative process, challenges we've conquered, and the teamwork that makes each venture successful.
            </p>
          </div>
        </div>
      </div>
      <div class="-mx-4 flex flex-wrap mx-4">
        <div class="w-full md:w-1/2 lg:w-1/3">
          <div class="mb-9 rounded-[20px] bg-white dark:bg-dark-2 mx-1 shadow-2">
            <img class="object-cover mb-5 w-full h-56 rounded-xl shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
              src="../assets/pexels-danish-ahmad-4125771.jpg" alt="" />
            <h4 class="text-dark dark:text-white mb-[14px] text-2xl font-semibold">
              Expertise
            </h4>
            <p class="text-body-color dark:text-dark-6">
              With over a decade of industry expertise in bonding agent, we thrive on collaboration to achieve our ultimate goals.
            </p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3">
          <div class="mb-9 rounded-[20px] bg-white dark:bg-dark-2 mx-1 shadow-2">
            <img class="object-cover mb-5 w-full h-56 rounded-xl shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
              src="../assets/pexels-mikael-blomkvist-8961030.jpg" alt="" />
            <h4 class="text-dark dark:text-white mb-[14px] text-2xl font-semibold">
              Free consulting
            </h4>
            <p class="text-body-color dark:text-dark-6">
              Provide clarity on the situation and present solutions during client counselling.
            </p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3">
          <div class="mb-9 rounded-[20px] bg-white dark:bg-dark-2 mx-1 shadow-2">
            <img class="object-cover mb-5 w-full h-56 rounded-xl shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
              src="../assets/pexels-tiger-lily-4481260.jpg" alt="" />
            <h4 class="text-dark dark:text-white mb-[14px] text-2xl font-semibold">
              Timely Delivery
            </h4>
            <p class="text-body-color dark:text-dark-6">
              Delivered on time and in compliance with customer requirements.
            </p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3">
          <div class="mb-9 rounded-[20px] bg-white dark:bg-dark-2 mx-1 shadow-2">
            <img class="object-cover mb-5 w-full h-56 rounded-xl shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
              src="../assets/pexels-zeynel-aksakal-6170589.jpg" alt="" />
            <h4 class="text-dark dark:text-white mb-[14px] text-2xl font-semibold">
              Site Demo
            </h4>
            <p class="text-body-color dark:text-dark-6">
              On-site demonstrations assist users in understanding how to effectively utilize and attain specific results.
            </p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3">
          <div class="mb-9 rounded-[20px] bg-white dark:bg-dark-2 mx-1 shadow-2">
            <img class="object-cover mb-5 w-full h-56 rounded-xl shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
              src="../assets/pexels-tima-miroshnichenko-6474471.jpg" alt="" />
            <h4 class="text-dark dark:text-white mb-[14px] text-2xl font-semibold">
              Site Visit
            </h4>
            <p class="text-body-color dark:text-dark-6">
              Site visits to oversee operations and ensure prompt resolution.
            </p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3">
          <div class="mb-9 rounded-[20px] bg-white dark:bg-dark-2 mx-1 shadow-2">
            <img class="object-cover mb-5 w-full h-56 rounded-xl shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
              src="../assets/pexels-aleksey-3680959.jpg" alt="" />
            <h4 class="text-dark dark:text-white mb-[14px] text-2xl font-semibold">
              Training
            </h4>
            <p class="text-body-color dark:text-dark-6">
              Provide bonding agent technical training to ensure our partners use the products correctly.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="overflow-hidden bg-slate-100">
    <div class="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <h2 className="sm:max-w-md lg:max-w-2xl text-black w-full font-bold text-2xl md:text-3xl mb-10">
            Video Gallery
          </h2>
      <div class="grid sm:grid-cols-2 lg:grid-cols-2 gap-6">
        
        <div class="aspect-video">
          <iframe src="https://www.youtube.com/embed/M3U9d0Z88lE?si=WrD4UH9dCX4CZW6K" frameborder="0"
            className="w-full h-full rounded-lg shadow-xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <div class="aspect-video">
          <iframe src="https://www.youtube.com/embed/U8uxDXxU70Q?si=bfLnUfnHYPTtzsNZ" frameborder="0"
            className="w-full h-full rounded-lg shadow-xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <div class="aspect-video">
          <iframe src="https://www.youtube.com/embed/IYlqziJkpsY?si=eJTFQV3fsgMq9kBH" frameborder="0"
            className="w-full h-full rounded-lg shadow-xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <div class="aspect-video">
          <iframe src="https://www.youtube.com/embed/GDDJX367ctU?si=l6Jj-bwyQQZ0hUeC" frameborder="0"
            className="w-full h-full rounded-lg shadow-xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <div class="aspect-video">
          <iframe src="https://www.youtube.com/embed/H7qX6pFfXFw?si=RdklXnxxR3kFzvQR" frameborder="0"
            className="w-full h-full rounded-lg shadow-xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
  <!-- ====== Services Section End -->
</template>
  
<script>

export default {
  name: 'ServicesPage',
  methods: {
    openPdf(){
      window.open("http://uromix.com.my/assets/product.pdf") 
    }
  }
}
</script>
  