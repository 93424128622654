<template>
  <section
    class="relative bg-[url(https://media.istockphoto.com/id/534500443/photo/laying-ceramic-tiles.webp?b=1&s=612x612&w=0&k=20&c=wDQLceRTXu70mpbvB0LPLUF7v9ojmqFrRpy85f6_wIk=)] bg-cover bg-center bg-no-repeat">
    <div class="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 sm:bg-gradient-to-r">
    </div>
    <div class="relative mx-auto max-w-[85rem] px-4 py-32 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="text-center sm:text-left max-lg">
        <h1 class="text-3xl font-extrabold sm:text-5xl">
          Some Useful Tips
        </h1>
       
        
      </div>
    </div>
  </section>
  <div class="justify-center flex-1 max-w-[85rem] px-4 py-4 mx-auto lg:py-6 md:px-6">
    <div class="text-gray-900">5 minute reading</div>
    <div class="mt-5 mb-5 text-2xl">The History of Tiles</div>
    <p>Ceramic tiles have been a popular material for interior and exterior decoration for thousands of years. They come
      in all shapes and sizes, different colours and glazes and can be used plain, decorated or as part of a mosaic. The
      origin of the word ceramic' comes from the greek word keramos', meaning pottery.The word tile' originates from the
      latin tegula' and its french derivative tuile' which is used more precisely that the English word tile'. A tuile' is
      a clay roof tile, whereas a tile' is made from earthenware and can be applied to any surface.Ceramics are an ancient
      form of craft, with examples dating back 4,000 years. Originating in the Middle East the earliest evidence of
      ceramic decorations dates back to Ancient Egypt in around 4,700 BCE. There have been further findings of enamel
      tiling uncovered in what was Assyria (near ancient Nineveh) and Babylon; these date back to the 7th Century BCE.</p>
    <p>The art of tiling finally spread further west, becoming popular in Europe during the eleventh century, when mosaic
      floorings and panels became prevalent. Tiles are made from clay, which once shaped and dried are fired in a kiln at
      very hot temperatures. This process hardens the tile, creatingbisque', which can then be glazed and fired for a
      second time. Tiles can be used unglazed, although this limits the colour range to the natural shades of clay.
      Mosaics are made using a variety of small, coloured tiles which are set into a pattern, forming a picture. The gaps
      between the pieces are then filled in with a plaster or cement based mix, which as well as adding an adhesive factor
      also provides a contrast colour to enhance the original picture.</p>
    <p>Ceramic tiles are a popular choice of material due to their aesthetic appeal; however they also have a durable
      quality which makes them a practical choice too. They can be used on a variety of surfaces; on walls, floors,
      ceilings, fireplaces or even externally. Ceramic tiles are an ancient art form which has been used throughout the
      ages. They have maintained their popularity and today remain an attractive and practical material for interior and
      exterior design. Modern advances have added to the range of shades, finishes and shapes available. In addition,
      there has been a resurgence of more traditional looks with terracotta and other, very natural, unglazed finishes,
      gaining popularity. Whatever your choice of finish, ceramic tiles offer both style and durability.</p>
  </div>
  <div class="justify-center flex-1 max-w-[85rem] px-4 py-4 mx-auto lg:py-6 md:px-6 border-t-2">
    <div class="text-gray-900">1 minute reading</div>
    <div class="mt-5 mb-5 text-2xl">How Do We Test the Adhesive Strength?</div>
    <p>A tensile pull out test can be done to test the adhesiveness of the tile adhesive. The instrument is used by SIRIM
      to testify the tensile pulling strength. Generally the instrument reading shall be in kilo newton (eg, 1.25 or 2.72
      KN), indicated to Force (F). But the reading shall be divided by the size of testing tile piece (Usually 50mm x
      50mm), indicated to Area (A), normally 2500mm². Thus, to obtain its Adhesive Strength, by theory of (F) divide by
      (A) , then KN convert to Newton (N) shall x 1000 in formula. For Example, (eg, 2.72 KN / 2500mm² x 1000 = 1.088
      N/mm² )</p>
  </div>
  <div class="justify-center flex-1 max-w-[85rem] px-4 py-4 mx-auto lg:py-6 md:px-6 border-t-2">
    <div class="text-gray-900">5 minute reading</div>
    <div class="mt-5 mb-5 text-2xl">Modern Tiles</div>
    <p>Ceramic tile became more popular as technology improved the firing process. At first, kilns were long tunnels
      with low heat, and tiles moved on rails slowly through. Painting and then re-firing the tiles became popular
      because it seriously improved the strength of the tiles.</p>
    <p>
    </p>
    <p>Then came the invention of roller kilns with much higher temperatures and shorter cycles for firing. These
      increased temperatures shortened the manufacturing process, which made the entire industry more efficient.</p>
    <p>
    </p>
    <p>Red body clay tiles and white body feldspar tiles were mass produced and readily available—and their strength
      attests to the fact that many are still around today!</p>
    <p>
    </p>
    <p>The late 20th century was when Italians developed the modern porcelain tile. Porcelain can remain stable
      through freezes and their subsequent thaws because their water absorption is less than 0.5%. They are strong
      enough to be used on space shuttles, where other materials would burn during re-entry.</p>
    <p>
    </p>
    <p>The latest tile technology allows for the entire tile to be the same color, not just the surface. This
      prevents the development of wear patterns that let the tile show its base color.</p>
    <p>
    </p>
    <p>They involve no glaze, so the color is permanent. Tiles can be silk-screened and digitally inked to mimic
      photographs. They can be made in ever-larger sizes, ever-smaller sizes, and ever more colors.</p>
    <p>
    </p>
    <p>While Italy remains the leading manufacturer of tile, many other countries are starting to give them a run
      for their money. Mexico, China, Brazil, Turkey and Spain are all major players in the industry.</p>
    <p>
    </p>
    <p>However, most of their manufacturing equipment comes from Italy, so it appears the grip the Italians have on
      the industry may be around for a while.</p>
    <p>
    </p>
    <p>When the tiles are being produced in larger size like 600mm x 600mm, 800mm x 800mm or even bigger. The next
      question is the adhesiveness of the tile grout.</p>
    <p>
    </p>
    <p>For the past years, the tile installers had used to lay the tile with Ordinary Portland cement but which is
      not suitable to install for large sized tiles due to low adhesiveness of the OPC. Where a higher expectation
      for the higher adhesiveness tile grout is needed.&nbsp;</p>
  </div>
  <div class="justify-center flex-1 max-w-[85rem] px-4 py-4 mx-auto lg:py-6 md:px-6 border-t-2">
    <div class="text-gray-900">5 minute reading</div>
    <div class="mt-5 mb-5 text-2xl">Why Tile Debond/ Pop up/ Peel off?</div>
    <div>
      The tile debond might happen due to the following reasons: The substrate has not been cleaned up before the tile
      installation. It might cause the hollow sound reflects underneath or tile debond after months or years.
    </div>
    <div>
      The Facts: Therefore the tile adhesive has not been applied on substrate (no bonding) because there was a layer of
      dust or dirt between the tile adhesive and substrates.
    </div>
    <div class="mt-5">
      The tile debond might happen due to the following reasons:
      Incorrect methodology or incorrect type of tile grout or tile adhesive used to install tiles. Please refer also TYPES OF TILES
    </div>
    <div>
      The Facts: Many tile installers might use the ordinary cement to install tiles that is irrelevant to any type or
      size of tiles. But this is absolutely incorrect. Nowadays, market 50kg ordinary cement we can buy from hardware shop
      is known as "Composite cement”. It is no longer with the type of "OPC - Ordinary Portland Cement” anymore.<br/>
      Lastly, larger tiles need higher bonding strength of tile adhesive.
    </div>
    <div class="mt-5">
      The tile debond might happen due to the following reasons:
      Tile gap is too small (at least 3mm for large size tile - as specified by most of the tile manufacturers)
    </div>
    <div>
      The Facts: If the tile gap is smaller than 3mm, it might cause the tile debond due to all tiles expand and contract with temperature and moisture changes.<br/>
      Again, the bonding strength is very important therefore EN12004 specified the tensile pull out strength must be above 1 newton per mm square (1 N/mm²) for a Class 2 (C2) tile adhesive.
    </div>
    <div class="mt-5">
      The tile debond might happen due to the following reasons:
      Poor workmanship : <br/>
      - Did not apply enough tile adhesive grout underneath the tile (Buttering / Scratching coat) - (at least 70%) <br/>
      - Did not use the correct tools to apply the tile adhesive (standard tool is the tile trowel) where it causes over fill or less fill tile adhesive grout underneath the tile
    </div>
    <div>
      The Facts: It will cause the tile debond if insufficient tile adhesive grout applied underneath the tiles. Less bonding strength achieved.<br/>
      Tile trowel is used to apply the tile adhesive grout evenly and effectively on the substrates.<br/>
      Keep holding and moving the trowel to force the tile adhesive grout adheres onto the substrates and spread even amount of grout for the tile underneath to adhere as well.<br/>
      The level of water added onto tile adhesive will significantly affect the bonding strength where the tile installer must be carefully reading the mixing ratio and methodology.
    </div>
  </div>
  <div class="justify-center flex-1 max-w-[85rem] px-4 py-4 mx-auto lg:py-6 md:px-6 border-t-2">
    <div class="text-gray-900">5 minute reading</div>
    <div class="mt-5 mb-5 text-2xl">The Standard - En 12004</div>
    <p>The introduction of the new EN standards for cement-based tile adhesives which is making new demands on tile adhesive producers. Standardisation of the norms involves more stringent testing, as well as specific classifications for different tile adhesive qualities, which results in a real challenge in meeting the standards.</p>
    <p>Included in the EN 12004 standard are two cement-based tile adhesive classifications: C1 which outlines general requirements, and C2 which describes improved or additional characteristics.These set out the minimum requirements for improved adhesion after 28 days at room temperature and under heat, water and freeze/thaw storage conditions. They also stipulate optional requirements for fast setting, reduced slip and extended open time. Sand and cement are the main constituents of cement-bonded, thin-bed adhesives and determine the properties to a large extent.</p>
    <p>However, no tile adhesive which can be applied by the thin-bed procedure is formulated with sand and cement alone. Numerous additives enable an endless range of variations in processing and end properties to satisfy the wishes of the end user.</p>
    <p>The need to be able to compare and to classify this enormous variety of products has existed for a long time. «Flexible adhesive», «standard adhesive» and «fluidized bed mortar» are all examples of attempts to categorise these products. However, it has often been the case that each region, each country or even each manufacturer, used different terminology. After much debate, the initiative for the regulation of adhesive mortars was finally agreed earlier this year, and with EN 12004 coming into effect, the quality requirements for cement-based tile adhesives will now be standardised Europe-wide, replacing conflicting national standards.</p>
  </div>
</template>
  
<script>

export default {
  name: 'InsightsPage',
}
</script>
  