<template>
  <div class="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
    <div class="max-w-2xl text-center mx-auto mb-10 lg:mb-14">
    <h2 class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">Project Gallery</h2>
    <p class="mt-1 text-gray-600 dark:text-gray-400">Explore the culmination of our hard work, creativity, and dedication showcased in these impressive projects.</p>
  </div>
    <div class="grid grid-cols-2 sm:grid-cols-4 gap-2">
    <div class="space-y-2">
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File1.jpg" alt="Image Description">
      <p class="p-2 font-medium">Graham Garden, Eco Grandeur Puncak Alam</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File2.jpg" alt="Image Description">
      <p class="p-2 font-medium">Akasa @ Cheras South</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File3.jpg" alt="Image Description">
      <p class="p-2 font-medium">Alice Smith International School Seri Kembangan</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File4.jpg" alt="Image Description">
      <p class="p-2 font-medium">Ara Sendayan</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File5.jpg" alt="Image Description">
      <p class="p-2 font-medium">Aurora Place Bukit Jalil</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File6.jpg" alt="Image Description">
      <p class="p-2 font-medium">BSN, Bank Simpanan Nasional Utama Shah Alam</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File7.jpg" alt="Image Description">
      <p class="p-2 font-medium">Chamang Glades, Bentong</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File32.jpeg" alt="Image Description">
      <p class="p-2 font-medium">The Arcuz, Kelana Jaya</p>
      </div>
    </div>
    <div class="space-y-2">
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File9.jpg" alt="Image Description">
      <p class="p-2 font-medium">Datum, Jelatik</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File10.jpg" alt="Image Description">
      <p class="p-2 font-medium">Dorsett Putrajaya</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File11.jpg" alt="Image Description">
      <p class="p-2 font-medium">Dorsett Residences Sri Hartamas</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File12.jpg" alt="Image Description">
      <p class="p-2 font-medium">Esclipse & CBD3, Cyberjaya</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File13.jpg" alt="Image Description">
      <p class="p-2 font-medium">Hospital Pengajar UPM Serdang</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File14.jpg" alt="Image Description">
      <p class="p-2 font-medium">J Dupion Residence Cheras</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File15.jpg" alt="Image Description">
      <p class="p-2 font-medium">JW Marriott Hotel, Bukit Bintang</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File16.jpg" alt="Image Description">
      <p class="p-2 font-medium">Kerinchi Station Trade Center Bangsar</p>
      </div>
    </div>
    <div class="space-y-2">
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File17.jpg" alt="Image Description">
      <p class="p-2 font-medium">Lake Park Residences Selayang</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File18.jpg" alt="Image Description">
      <p class="p-2 font-medium">Taman Sri Indah @ Jalan Kebun</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File19.jpg" alt="Image Description">
      <p class="p-2 font-medium">Mahogany Park, Kajang</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File20.jpg" alt="Image Description">
      <p class="p-2 font-medium">Masreco-19, Cyberjaya</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File21.jpg" alt="Image Description">
      <p class="p-2 font-medium">Palm Hill Residence, Kajang</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File22.jpeg" alt="Image Description">
      <p class="p-2 font-medium">Paragon Suites, Johor</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File23.jpeg" alt="Image Description">
      <p class="p-2 font-medium">Paraiso Residence Bukit Jalil</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File24.jpeg" alt="Image Description">
      <p class="p-2 font-medium">Precinct Arista, Taman Gaya</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File8.jpg" alt="Image Description">
      <p class="p-2 font-medium">City of Elmina Valley</p>
      </div>
    </div>
    <div class="space-y-2">
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File25.jpeg" alt="Image Description">
      <p class="p-2 font-medium">Prima Apartment Kampung Paloh</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File26.jpeg" alt="Image Description">
      <p class="p-2 font-medium">PV 9 Residence, Setapak</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File27.jpeg" alt="Image Description">
      <p class="p-2 font-medium">Resident Permai, Kajang</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File28.jpeg" alt="Image Description">
      <p class="p-2 font-medium">Sama-sama Hotel</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File29.jpeg" alt="Image Description">
      <p class="p-2 font-medium">Taman Bentara @ Jalan Merpati, Telok Panglima Garang</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File30.jpeg" alt="Image Description">
      <p class="p-2 font-medium">Taman Nakhoda @ (Lorong Puyuh), Telok Panglima Garang</p>
      </div>
      <div class="border rounded-md shadow-md"><img class="w-full sm:w-full h-auto object-cover rounded-t-md" src="../assets/File31.jpeg" alt="Image Description">
      <p class="p-2 font-medium">The Arc Ukay Ampang</p>
      </div>
    </div>
  </div>
  </div>
</template>
  
<script>

export default {
  name: 'ProjectPage',
}
</script>
  