<template>
  <section
    class="relative bg-[url(https://media.istockphoto.com/id/980300158/photo/worker-installing-ceramic-floor-tiles.webp?b=1&s=612x612&w=0&k=20&c=PchB4yuiEXrt6TryeOJm4QhDO2L3p6CSZXYpeXmTQd4=)] bg-cover bg-center bg-no-repeat">
    <div class="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 sm:bg-gradient-to-r">
    </div>
    <div class="relative mx-auto max-w-[85rem] px-4 py-32 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="text-center sm:text-left max-lg">
        <h1 class="text-3xl font-extrabold sm:text-5xl">
          Exploring further details related to the business.
        </h1>
        <p class="mt-4 max-w-lg sm:text-xl/relaxed">
          Feel free to contact us now
        </p>
        <div class="mt-8 flex flex-wrap gap-4 text-center">
          <a href="/contact"
            class="block w-full rounded bg-green-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-green-700 focus:outline-none  active:bg-green-500 sm:w-auto">
            Reach out to us
          </a>
        </div>
      </div>
    </div>
  </section>
  <footer class="w-full bg-slate-900">
    <div class="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">
      <!-- Grid -->
      <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-6">
        <div class="col-span-1">
          <h4 class="font-semibold text-gray-100">Uromix</h4>

          <div class="mt-3 grid space-y-1">
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">UROMINE
                GLOBAL SDN BHD</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">201401030629
                (1106715-A)</a></p>
          </div>
        </div>

        <!-- End Col -->

        <div class="col-span-1">
          <h4 class="font-semibold text-gray-100">Company</h4>

          <div class="mt-3 grid space-y-1">
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/about">About us</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/solutions">Our Services</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/project">Project Gallery</a></p>
          </div>
        </div>
        <!-- End Col -->

        <div class="col-span-1">
          <h4 class="font-semibold text-gray-100">Products</h4>

          <div class="mt-3 grid space-y-1">
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/products/urocrete">UROCRETE™</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/products/urohancer">UROHANCER</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/products/uroprime">UROPRIME</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/products/uroPlasterBond">URO PLASTER BOND</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/products/uroplastPremium">UROPLAST PREMIUM</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/products/urofibreFibreAdhiese">UROFIBRE & FIBRE ADHESIVE</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/products/urobond600Maxi">UROBOND 600 MAXI</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/products/urobond600">UROBOND 600™</a></p>
          </div>
        </div>
        <!-- End Col -->



        <div class="col-span-1">
          <h4 class="font-semibold text-gray-100">Reach Us</h4>

          <div class="mt-3 grid space-y-1">
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="tel:+60332918748">+603-3291 8748</a></p>
            <p><a
                class="inline-flex gap-x-2 text-gray-400 text-xs hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="mailto:marketing@uromix.com.my">marketing@uromix.com.my</a></p>
          </div>
        </div>
        <!-- End Col -->
      </div>
      <!-- End Grid -->

      <div class="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
        <div class="flex justify-between items-center">
          <p class="text-gray-400 text-xs">© 2013 - {{ new Date().getFullYear() }} UROMINE GLOBAL SDN BHD 201401030629
            (1106715-A) . All rights reserved.</p>
        </div>
        <!-- End Col -->


      </div>
    </div>
  </footer>
  <div class='layout'>
    <VueSocialChat icon class="whatsapp-chat mb-5 items-center" :attendants="attendants">
      <template #header>
        <p>Click on one of our attendants below to chat on WhatsApp.</p>
      </template>
      <template #button>
        <img class="whatsappIcon" src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp" aria-hidden="true">
      </template>
      <template #footer>
        <small>Operation hour : <br>Monday - Friday : 8:30am to 5:30pm <br>(Lunch hour: 12:00 - 1:00pm)<br>Saturday : 8:30am - 12:00pm<br>Sunday : Close</small>
      </template>
    </VueSocialChat>
  </div>
</template>
  
<script>
export default {
  name: 'FooterNav',
  setup() {
    const attendants = [
      {
        app: 'whatsapp',
        label: 'Business Consultant',
        name: 'Uromix',
        number: '60192080393',
        text: 'Hi, i interesting in Uromix product',
        avatar: {
          src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
          alt: 'Alan Ktquez avatar'
        }
      },
    ]

    return { attendants }
  }
}
</script>
  