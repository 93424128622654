<template>
  <section class="bg-white">
    <div class="max-w-[85rem] mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div class="max-w-2xl lg:max-w-4xl mx-auto text-center">
        <h2 class="text-3xl font-extrabold text-gray-900">Business Details</h2>
      </div>
      <div class="mt-16 lg:mt-20">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div class="rounded-lg overflow-hidden">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.9826508197657!2d101.39452127561628!3d3.0992696534559037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc57f79b73c641%3A0x4d180fef406f8760!2sUromine%20Global%20Sdn.%20Bhd.!5e0!3m2!1sen!2smy!4v1705846164951!5m2!1sen!2smy"
              width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div>
            <div class="max-w-full mx-auto rounded-lg overflow-hidden">
              <div class="px-6 py-4">
                <h3 class="text-lg font-medium text-gray-900">Our Address</h3>
                <p class="text-lg font-medium">Uromine Global Sdn. Bhd.</p>
                <a href="https://goo.gl/maps/2YPaz5fMfu5FSZBw8" class="mt-1 text-blue-600">19, Jalan 5, Klang Central
                  Industrial Park, 5 1/2 miles,
                  Jalan Kapar 42200 Klang,
                  Selangor Darul Ehsan,
                  Malaysia.</a>
              </div>
              <div class="border-t border-gray-200 px-6 py-4">
                <h3 class="text-lg font-medium text-gray-900">Hours</h3>
                <p class="mt-1 text-gray-600">Monday - Friday: 8.30am - 5.30pm (Lunch hour: 12 - 1pm)</p>
                <p class="mt-1 text-gray-600">Saturday: 8.30am - 12pm</p>
                <p class="mt-1 text-gray-600">Sunday: Closed</p>
              </div>
              <div class="border-t border-gray-200 px-6 py-4">
                <h3 class="text-lg font-medium text-gray-900">Contact</h3>
                <p class="mt-1 text-gray-600">Email: <a class="text-blue-600"
                    href="mailto:marketing@uromix.com.my">marketing@uromix.com.my</a></p>
                <p class="mt-1 text-gray-600">Phone: <a class="text-blue-600" href="tel:+60332918748">+603-3291 8748</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>


export default {
  name: 'ContactPage'
}
</script>
  